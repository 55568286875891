
import EventManager               from '@brainscape/event-manager';
import React                      from 'react';
import Spinner                    from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

class Pulldown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isListeningToOutsideClicks: false,
    };

    /*
      this.props:
        fieldName,
        isOpen,
        isUserPro,
        isProcessing,
        onButtonClick,
        onOptionClick,
        onOutsideClick,
        options,
        optionStyle
        placeholderText,
        selectedValue,
        shouldSuppressNullOption,
        validationMessage,
    */

    this.events = new EventManager();
    this.elem = null;
    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    if (this.props.isOpen && this.props.onOutsideClick && !this.state.isListeningToOutsideClicks) {
      this.startOutsideClickMonitor();
    }

    this.events.addListeners([
      ['dropdown:button-clicked',         this.handleDropdownButtonClicked],
      ['dropdown:close-all-requested',    this.handleDropdownCloseAllRequested],
    ]);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen && this.props.onOutsideClick && !this.state.isListeningToOutsideClicks) {

      this.stopOutsideClickMonitor();
      this.startOutsideClickMonitor();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.stopOutsideClickMonitor();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let isOpen = (this.props.isOpen) ? 'is-open' : 'is-closed';
    let isProcessing = (this.props.isProcessing) ? 'is-processing' : '';
    let classes = toClassStr(['pulldown', this.props.fieldName, isOpen, isProcessing, this.props.addClasses]);

    return (

      <div className={classes} ref={(elem) => { this.elem = elem }}>
        <div className="pulldown-button" onClick={(e) => this.handleButtonClick(e)}>
          {this.renderDisplayedValue()}
          {this.renderSpinner()}
        </div>
       <ul className={`pulldown-list ${this.props.fieldName}-list`}>
          {this.renderOptions()}
        </ul>
        <input className="pulldown-value" type="hidden" name={this.props.fieldName} value={this.props.selectedValue || ''}/>
        {this.renderValidationMessage()}
      </div>
    );
  }

  renderDisplayedValue() {
    if (this.props.selectedValue == -1) {
      return (
        <p className="pulldown-placeholder">{this.props.placeholderText || 'Choose one'}</p>
      );
    } else {
      let selectedLabel = this.getOptionLabel(this.props.selectedValue);
      return (
        <p className="pulldown-selected-option">{selectedLabel}</p>
      );
    }
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner
        addClasses="blue"
      />
    );
  }

  renderOptions() {
    let options = this.props.options.map((option, index) => {
      return (this.renderOption(option, index));
    });

    return options;
  }

  renderOption(option, index) {
    const proClass = (option.onlyPro && !this.props.isUserPro) ? 'pro-required' : '';

    if (this.props.shouldSuppressNullOption && (option.isNullOption || option.id == '-1')) {
      return null;
    }

    if (option.id == '---') {

      return (
        <li
          className='divider'
          id='---'
          key={index}
        />
      );
    }

    if (option.isNullOption || option.id == '-1') {
      let placeholderText = this.props.placeholderText || 'Choose One...';

      return (
        <li
          className={`menu-option option null-option ${proClass}`}
          id='-1'
          key={index}
          onClick={this.handleOptionClick}
          style={this.props.optionStyle}
        >
          {placeholderText}
        </li>
      );
    }

    if (option.id == this.props.selectedValue) {
      return (
        <li
          className={`menu-option option option-${option.id} ${proClass}`}
          data-label={option.label}
          id={option.id}
          key={index}
          onClick={this.handleOptionClick}
          selected='selected'
          style={this.props.optionStyle}
        >
          {option.label}
        </li>
      );
    }

    return (
      <li
        className={`menu-option option option-${option.id} ${proClass}`}
        data-label={option.label}
        id={option.id}
        key={index}
        onClick={this.handleOptionClick}
        style={this.props.optionStyle}
      >
        {option.label}
      </li>
    );
  }

  renderValidationMessage() {
    if (!this.props.validationMessage) {
      return null;
    }

    return (
      <p className="validation-message">{this.props.validationMessage}</p>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOutsideClick = (e) => {
    if (this.elem && this.elem.contains(e.target)) {
      return false;
    }

    e.stopPropagation();

    this.stopOutsideClickMonitor();
    this.props.onOutsideClick();
  }

  handleButtonClick = (e) => {
    e.stopPropagation();

    this.publishDropdownButtonClick(e.target);
    
    this.stopOutsideClickMonitor();
    this.props.onButtonClick();
  }

  handleOptionClick = (e) => {
    e.stopPropagation();

    this.stopOutsideClickMonitor();
    this.props.onOptionClick(e.target.id);
  }

  handleDropdownButtonClicked = (data) => {
    // this handler is fired whenever *any* pulldown button or options menu button in the app is clicked

    if (!this._isMounted) {
      return false;
    }

    if (this.elem && this.elem.contains(data.eTarget)) {
      // this handler is detecting someone clicking this pulldown instance
      return false;
    }

    // event should be processed like an outside click
    this.stopOutsideClickMonitor();
    this.props.onOutsideClick();
  }

  handleDropdownCloseAllRequested = (data) => {
    if (!this._isMounted) {
      return false;
    }

    // event should be processed like an outside click
    this.stopOutsideClickMonitor();
    this.props.onOutsideClick();
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishDropdownButtonClick = (eTarget) => {
    EventManager.emitEvent('dropdown:button-clicked', {eTarget: eTarget});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getOptionLabel(optionValue) {
    if ((!optionValue && optionValue !== 0) || optionValue == -1) {
      return null;
    }

    for(var i = 0; i < this.props.options.length; i++){
      var option = this.props.options[i];
      if (option.id == optionValue) {
        return option.label;
      }
    }

    return null;
  }

  startOutsideClickMonitor = () => {
    document.addEventListener('click', this.handleOutsideClick, true);
    if (this._isMounted) {
      this.setState({
        isListeningToOutsideClicks: true
      });
    }
  }

  stopOutsideClickMonitor = () => {
    document.removeEventListener('click', this.handleOutsideClick, true);
    this.setState({
      isListeningToOutsideClicks: false,
    })
  }
}

export default Pulldown;
