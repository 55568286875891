
import model                  from '_core/newModel';


const packDeckCardImport = {
  // accepts a packId and a set of cards as deckData, and creates a new deck in the pack

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, cards, deckName, isSilent=false) {
    const path = `packs/${packId}/decks/import`;
    const deckData = {
      name: deckName || 'Untitled Deck',
      cards: cards,
    };

    return model.create(path, deckData).then(rs => {
      if (!isSilent) {
        model.publish('pack-deck-cards:generated', {
          deckId: rs.deckId,
          packId: packId,
          newCardCount: cards.length,
        });
      }

      return rs;
    });
  },


  /*
  ==================================================
   CONVENIENCE METHODS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default packDeckCardImport;
