
import EventManager                             from '@brainscape/event-manager';
import IncrementalCounter                       from '_views/shared/IncrementalCounter';
import PillButton                               from '_views/shared/PillButton';
import Pulldown                                 from '_views/shared/Pulldown';
import React, { useState, useEffect, useRef }   from 'react';
import SimpleTextButton                         from '_views/shared/SimpleTextButton';
import SvgIcon                                  from '_views/shared/SvgIcon';
import TextField                                from '_views/shared/TextField';


const HELP_ARTICLE_URL = 'https://brainscape.zendesk.com/hc/en-us/articles/29481651085965-Using-AI-to-create-flashcards';
const MAX_CHAR_COUNT = 100;

const MAX_CARDS = 100;
const MIN_CARDS = 5;
const DEFAULT_CARDS = 25;
const STEP_COUNT = 5;

const LANGUAGE_OPTIONS = [
  { id: 'English'     , label: 'English' },
  { id: 'Spanish'     , label: 'Spanish' },
  { id: 'French'      , label: 'French' },
  { id: 'German'      , label: 'German' },
  { id: 'Chinese'     , label: 'Chinese' },
  { id: 'Japanese'    , label: 'Japanese' },
  { id: 'Korean'      , label: 'Korean' },
  { id: 'Russian'     , label: 'Russian' },
  { id: 'Arabic'      , label: 'Arabic' },
  { id: 'Portuguese'  , label: 'Portuguese' },
  { id: 'Italian'     , label: 'Italian' },
  { id: 'Dutch'       , label: 'Dutch' },
  { id: 'Turkish'     , label: 'Turkish' },
  { id: 'Hindi'       , label: 'Hindi' },      
];

const DEFAULT_LANGUAGE_OPTION = 'English';

const GenerateFromTopicPanel = ({
  initialInputTopicData,
}) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */
  const [inputText, setInputText] = useState(initialInputTopicData?.topic || '');
  const [isQuestionPulldownOpen, setIsQuestionPulldownOpen] = useState(false);
  const [isAnswerPulldownOpen, setIsAnswerPulldownOpen] = useState(false);
  const [selectedQuestionLanguageId, setSelectedQuestionLanguageId] = useState(initialInputTopicData?.questionLanguage || DEFAULT_LANGUAGE_OPTION);
  const [selectedAnswerLanguageId, setSelectedAnswerLanguageId] = useState(initialInputTopicData?.answerLanguage || DEFAULT_LANGUAGE_OPTION);
  const [showAdvancedOptions, setShowAdvancedOptions] =  useState(false);

  const cardCount = useRef(initialInputTopicData?.cardCount || DEFAULT_CARDS);
  const questionLanguageId = useRef(initialInputTopicData?.questionLanguage || null);
  const answerLanguageId = useRef(initialInputTopicData?.answerLanguage || null);

  useEffect(() => {
    questionLanguageId.current = selectedQuestionLanguageId;
  }, [selectedQuestionLanguageId]);  

  useEffect(() => {
    answerLanguageId.current = selectedAnswerLanguageId;
  }, [selectedAnswerLanguageId]);  




  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleAdvancedButtonClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  }

  const handleAnswerPulldownButtonClick = () => {
    setIsAnswerPulldownOpen((prev) => !prev);
  }

  const handleAnswerPulldownOptionClick = (languageId) => {
    setIsAnswerPulldownOpen(false);
    setSelectedAnswerLanguageId(languageId)
  }

  const handleAnswerPulldownOutsideClick = () => {
    setIsAnswerPulldownOpen(false);
  }

  const handleCardCountChange = (newCardCount) => {
    cardCount.current = newCardCount;
  }

  const handleCancelButtonClick = () => {
    triggerCancelRequest();
  }

  const handleInputTextFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    triggerInputTopicSubmit();
  }

  const handleQuestionPulldownButtonClick = () => {
    setIsQuestionPulldownOpen((prev) => !prev);
  }

  const handleQuestionPulldownOptionClick = (languageId) => {
    setIsQuestionPulldownOpen(false);
    setSelectedQuestionLanguageId(languageId)
  }

  const handleQuestionPulldownOutsideClick = () => {
    setIsQuestionPulldownOpen(false);
  }

  const handleTextChange = (e) => {
    if (e.target.value.length <= MAX_CHAR_COUNT) {
      setInputText(e.target.value);
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerCancelRequest = () => {
    EventManager.emitEvent('generate-from-topic-panel:cancel-topic-request', {});
  }

  const triggerInputTopicSubmit = () => {
    EventManager.emitEvent('generate-from-topic-panel:input-topic-submit', {
      answerLanguage: answerLanguageId.current,
      cardCount: cardCount.current,
      questionLanguage: questionLanguageId.current,
      topic: inputText,
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderQuestionLanguagePulldown= () => {
    return (
      <div className="language-label-and-pulldown">

        <div className='language-label'>Question:</div>

        <Pulldown
          addClasses='language-pulldown question'
          isOpen={isQuestionPulldownOpen}
          options={LANGUAGE_OPTIONS}
          fieldName='language'
          placeholderText='Select question side language...'
          selectedValue={selectedQuestionLanguageId || '-1'}
          shouldSuppressNullOption={false}
          onButtonClick={handleQuestionPulldownButtonClick}
          onOptionClick={handleQuestionPulldownOptionClick}
          onOutsideClick={handleQuestionPulldownOutsideClick}
        />
      </div>
    );
  }

  const renderAnswerLanguagePulldown= () => {
    return (
      <div className="language-label-and-pulldown">

        <div className='language-label'>Answer:</div>

        <Pulldown
          addClasses='language-pulldown answer'
          isOpen={isAnswerPulldownOpen}
          options={LANGUAGE_OPTIONS}
          fieldName='language'
          placeholderText='Select answer side language...'
          selectedValue={selectedAnswerLanguageId || '-1'}
          shouldSuppressNullOption={false}
          onButtonClick={handleAnswerPulldownButtonClick}
          onOptionClick={handleAnswerPulldownOptionClick}
          onOutsideClick={handleAnswerPulldownOutsideClick}
        />
      </div>
    );
  }


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  return (
    <div className="generate-from-topic-panel">
      <div className='modal-image generate-from-topic'>
        <SvgIcon
          svgIconTag="generate-from-topic-icon"
          color={'tertiary'}
        />
      </div>

      <div className='modal-title'>Tell AI what you want</div>

      <div className='modal-guidance'>
        Please make up to <IncrementalCounter
          defaultVal={cardCount.current || DEFAULT_CARDS}
          maxVal={MAX_CARDS}
          minVal={MIN_CARDS}
          onChange={handleCardCountChange}
          stepCount={STEP_COUNT}
        /> flashcards about:
      </div>

      <form className="generate-from-topic-form" onSubmit={handleInputTextFormSubmit}>
        <TextField      
          addClasses='questions-and-answers-list-field'    
          onChange={handleTextChange}    
          placeholder='E.g. "U.S. State Capitals" or "U.S. History 1911-1914"'
          type='input'
          value={inputText}
        />

        <div className='generate-from-topic-info'>
          <div className="field-caption">
            <div className="left-field-caption">
              <div className='char-counter'>{inputText.length} / {MAX_CHAR_COUNT} characters</div>
            </div> 
            <div className="right-field-caption">
              <div className="right-caption">
                <div className="advanced-option" onClick={handleAdvancedButtonClick}>
                  Advanced options
                </div>
                <a className="caption-link" href={HELP_ARTICLE_URL} target='_blank' rel="nofollow noopener">Learn more.</a>
              </div>
            </div>
          </div>
        </div>

        {showAdvancedOptions && (
          <div className="advanced-options">
            {renderQuestionLanguagePulldown()}
            {renderAnswerLanguagePulldown()}
          </div>
        )}
      </form>
  
      <div className='modal-actions'>
        <SimpleTextButton
          addClasses='cancel-modal-text-button'
          label='Cancel'
          onClick={handleCancelButtonClick}
        />
  
        <PillButton
          addClasses='resolve-modal-button pill-button-emphasized'
          isDisabled={inputText.trim() === ''}
          label='Generate'
          onClick={handleInputTextFormSubmit}
        />
      </div>
    </div>
  )
}

export default GenerateFromTopicPanel;
