
import { apiPost } from '_core/Api2';
import { SupportLevel } from 'parse-card-list';
import DelayedResultsHandler from '_utils/DelayedResultsHelper';

const MT = [
  'text/comma-separated-values',
  'text/csv',
  'text/plain',
  'text/tab-separated-values',
  'text/tsv',
];

const PARSING_TIME_LIMIT = (1000 * 60 * 3.5); // 3.5 minutes


const AiListParserService = {
  mimeTypes: MT,
  name: 'Analyze Using AI 🤖',
  skipDefault: true,

  check: function(mimeType, body) {
    // We only want to return Maybe or No. We don't want to be the default
    // parser because this will cost GPT tokens.
    if (MT.includes(mimeType)) return SupportLevel.Maybe;

    return SupportLevel.No;
  },

  parse: function(_mimeType, body) {
    const params = {
      mimeType: 'text/plain',
      body: new TextDecoder().decode(body),
    };

    return apiPost('ai/card_list', params)
      .then(function(rs) {
        return DelayedResultsHandler.handleDelayedResults(rs, PARSING_TIME_LIMIT)
          .then(function(delayedResult) {
            if (delayedResult && delayedResult.cards) {
              return delayedResult.cards;
            } else {
              return Promise.reject(new Error('No cards found in the result.'));
            }
          })
          .catch(function(err) {
            err.parser = 'AiListParser';
            return Promise.reject(err);
          });
      })
      .catch(err => {
        err.parser = 'AiListParser';
        return Promise.reject(err);
      });
  },
};

export default AiListParserService;
