
import EventManager                     from '@brainscape/event-manager';
import ButtonInfoWrapper                from '_views/shared/ButtonInfoWrapper';
import PillButton                       from '_views/shared/PillButton';
import React                            from 'react';
import SvgIcon                          from '_views/shared/SvgIcon';

const HELP_ARTICLE_URL = 'https://brainscape.zendesk.com/hc/en-us/articles/115002369931-How-do-I-import-a-various-files-correctly-to-create-flashcards-csv-text-xlsx-docx-photos-lessons';


const ImportPasteFlashcardsPanel = ({
  acceptedFileTypes,
}) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handlePasteTextButtonClick = () => {
    triggerPasteTextRequest();
  };

  const handleUploadFileButtonClick = () => {
    triggerUploadFileRequest();
  };



  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerPasteTextRequest = () => {
    EventManager.emitEvent('import-paste-flashcards-panel:paste-text-request', {});
  }

  const triggerUploadFileRequest = () => {
    EventManager.emitEvent('import-paste-flashcards-panel:upload-file-request', {});
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  return (
    <div className="import-paste-flashcards-panel">

      <div className='modal-image custom-import-list'>
        <SvgIcon
          svgIconTag="custom-import-list-icon"
          color={'tertiary'}
        />
      </div>

      <div className='modal-title'>Import/Paste Flashcards</div>

      <div className='modal-guidance'>How would you like to import your flashcards?</div>
      <div className='modal-actions'>

        <ButtonInfoWrapper
          additionalText='(Existing Q&A, Terms, etc.)'
        >
          <PillButton
            addClasses='resolve-modal-button'
            label='Paste list'
            onClick={handlePasteTextButtonClick}
          />
        </ButtonInfoWrapper>

        <ButtonInfoWrapper
          additionalText={`(${acceptedFileTypes?.join(', ')})`}
          >
          <PillButton
            addClasses='resolve-modal-button'
            label='Upload file'
            onClick={handleUploadFileButtonClick}
          />
        </ButtonInfoWrapper>
      </div>
    </div>
  );
}

export default ImportPasteFlashcardsPanel;
