
import EventManager                             from '@brainscape/event-manager';
import React, { useState, useEffect, useRef }   from 'react';

import { toClassStr }                           from '_utils/UiHelper';


const colorMapping = {
  primary: '#29A5DC',
  secondary: '#FF8243',
  tertiary: '#7D93A4',
  white: '#FFFFFF',
};


const SvgIcon = ({ addClasses, svgIconTag, color = 'white', hoverColor, onClick, tooltipContent, tooltipPosition, shouldInheritColor=false }) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [isHovered, setIsHovered] = useState(false);
  const elemRef = useRef(null);

  useEffect(() => {
    return () => {
      triggerTooltipClose();
    };
  }, []);

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  const handleMouseEnter = () => {
    setIsHovered(true);

    if (tooltipContent) {
      triggerTooltipOpen();
    }
  }

  const handleMouseLeave = () => {
    setIsHovered(false);

    if (tooltipContent) {
      triggerTooltipClose();
    }
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const currentColor = (shouldInheritColor) ? 'currentColor' : (isHovered && hoverColor) ? colorMapping[hoverColor] : colorMapping[color] || color;
  const dynamicFillColor = color == 'white' ? '#7D93A4' : 'white';
  
  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: tooltipContent,
      elem: elemRef.current,
      position: tooltipPosition,
    });
  };

  const triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  /*
  ==================================================
   SUB-RENDERERS
  ==================================================
  */

  const renderGlyph = () => {
    switch (svgIconTag) {
      case 'import-list-icon':
        return (
          <svg viewBox="0 0 37 42" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="3" fill="white" />
            <rect x="3.77734" y="4.21875" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="3.77734" y="12.7266" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="3.77734" y="21.4141" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="16.9102" y="4.21875" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="16.9102" y="12.7266" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="16.9102" y="21.4141" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="13.5" y="19" width="22" height="22" rx="11" fill="currentColor" stroke="white" strokeWidth="2" />
            <path d="M20.25 28.936L24.186 25L28.122 28.936M24.186 25.5467V35Z" fill="currentColor" />
            <path d="M20.25 28.936L24.186 25L28.122 28.936M24.186 25.5467V35" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
          </svg>
        );
      case 'generate-from-topic-icon':
        return (
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.79292 11.2933L11.2927 6.79359L16.5254 12.0263L12.0257 16.526L6.79292 11.2933ZM18.0408 13.5408L13.5415 18.0408L25.4464 30L30 25.4464L18.0408 13.5408ZM0 9.64286H5.35714V11.7857H0V9.64286ZM2.38554 3.90067L3.90074 2.3854L7.68884 6.1735L6.17364 7.6887L2.38554 3.90067ZM9.64286 0H11.7857V5.35714H9.64286V0ZM13.7491 6.17377L17.5372 2.38567L19.0524 3.90087L15.2643 7.68897L13.7491 6.17377ZM2.38527 17.5371L6.17337 13.749L7.68857 15.2642L3.90047 19.0523L2.38527 17.5371Z" fill={currentColor} />
          </svg>
        );
      case 'summarize-from-content-icon':
        return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.457 6.35274V11.5781C12.457 11.616 12.4721 11.6523 12.4989 11.6791C12.5257 11.7059 12.562 11.7209 12.5999 11.7209H17.8252C17.8394 11.721 17.8532 11.7168 17.8649 11.709C17.8767 11.7011 17.8858 11.69 17.8912 11.677C17.8967 11.6639 17.8981 11.6496 17.8954 11.6358C17.8927 11.6219 17.8859 11.6092 17.876 11.5992L12.5788 6.30202C12.5688 6.29207 12.5561 6.28531 12.5422 6.28259C12.5284 6.27987 12.514 6.28131 12.501 6.28674C12.488 6.29216 12.4768 6.30133 12.469 6.31307C12.4612 6.32482 12.457 6.33862 12.457 6.35274Z" fill={currentColor} />
            <path d="M11.5977 12.8571C11.5219 12.8571 11.4492 12.827 11.3956 12.7735C11.342 12.7199 11.3119 12.6472 11.3119 12.5714V6H6.02623C5.91256 6 5.80355 6.04515 5.72318 6.12553C5.64281 6.2059 5.59766 6.31491 5.59766 6.42857V21.5714C5.59766 21.6851 5.64281 21.7941 5.72318 21.8745C5.80355 21.9548 5.91256 22 6.02623 22H17.7405C17.8542 22 17.9632 21.9548 18.0436 21.8745C18.1239 21.7941 18.1691 21.6851 18.1691 21.5714V12.8571H11.5977ZM15.3119 18.5714H8.4548V17.4286H15.3119V18.5714ZM15.3119 15.7143H8.4548V14.5714H15.3119V15.7143Z" fill={currentColor} />
            <g clipPath="url(#clip0_4567_11232)">
            <path d="M18.25 9L17.4316 6.81844L15.25 6L17.4316 5.18156L18.25 3L19.0684 5.18156L21.25 6L19.0684 6.81844L18.25 9ZM16.375 3.75L16.0067 2.74328L15 2.375L16.0067 2.00672L16.375 1L16.7433 2.00672L17.75 2.375L16.7433 2.74328L16.375 3.75ZM21.25 5L20.7639 3.73609L19.5 3.25L20.7639 2.76391L21.25 1.5L21.7361 2.76391L23 3.25L21.7361 3.73609L21.25 5Z" fill={currentColor}/>
            </g>
            <defs>
            <clipPath id="clip0_4567_11232">
            <rect width="8" height="8" fill={currentColor} transform="translate(15 1)"/>
            </clipPath>
            </defs>
          </svg>
        );
        case 'custom-import-list-icon':
          return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 7H11V9H5V7.5" stroke={currentColor} strokeLinecap="square"/>
              <path d="M5 11H11V13H5V11.5" stroke={currentColor} strokeLinecap="square"/>
              <path d="M5 15H11V17H5V15.5" stroke={currentColor} strokeLinecap="square"/>
              <path d="M13 7H19V9H13V7.5" stroke={currentColor} strokeLinecap="square"/>
              <path d="M13 11H19V13H13V11.5" stroke={currentColor} strokeLinecap="square"/>
              <path d="M13 15H19V17H13V15.5" stroke={currentColor} strokeLinecap="square"/>
              <path d="M2 19V5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19Z" stroke={currentColor} strokeLinecap="square"/>
              <rect x="5" y="7" width="6" height="2" fill={currentColor}/>
              <rect x="13" y="7" width="6" height="2" fill={currentColor}/>
              <rect x="5" y="11" width="6" height="2" fill={currentColor}/>
              <rect x="13" y="11" width="6" height="2" fill={currentColor}/>
              <rect x="5" y="15" width="6" height="2" fill={currentColor}/>
              <rect x="13" y="15" width="6" height="2" fill={currentColor}/>
              <rect x="13.5" y="12.5" width="10" height="10" rx="5" fill={dynamicFillColor}/>
              <rect x="13.5" y="12.5" width="10" height="10" rx="5" stroke={currentColor}/>
              <path d="M16.8984 16.5L18.5651 15L19.8984 16.5" stroke={currentColor} strokeLinecap="square"/>
              <path d="M18.5 16V20" stroke={currentColor} strokeLinecap="square"/>
            </svg>
          );
      default:
        return null;
    }
  };

  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['svg-icon', svgIconTag, addClasses]);

  return (
    <div 
      className={classes}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={elemRef}
    >
      {renderGlyph()}
    </div>
  );
}

export default SvgIcon;
