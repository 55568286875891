
// substrate and utils
import EventManager               from '@brainscape/event-manager';
import NumberHelper               from '_utils/NumberHelper';
import PropTypes                  from 'prop-types';
import React                      from 'react';
import StudyHelper                from '_utils/StudyHelper';
import Tracker                    from '_utils/Tracker';
import UiHelper                   from '_utils/UiHelper';
import {toClassStr}               from '_utils/UiHelper';

// models
import packDeck                   from '_models/packDeck';
import packDeckTransform          from '_models/packDeckTransform';

// sub-components
import DeckOptionsButton          from '_views/shared/DeckOptionsButton';
import DynamicTooltipIcon         from '_views/shared/DynamicTooltipIcon';
import Modal                      from '_views/shared/Modal';
import PillButton                 from '_views/shared/PillButton';
import RoundCheckbox              from '_views/shared/RoundCheckbox';
import SimpleProgressBar          from '_views/shared/SimpleProgressBar';
import StudyButton                from '_views/shared/StudyButton';
import RichContentTooltip              from '_views/shared/RichContentTooltip';

import {
  BrowseButton,
  DismissButton,
  DragButton, 
  EditButton,
  ForwardButton,
  InfoButton,
  PreviewButton,
}                                 from '_views/shared/IconButton';


const PT = {
  addClasses:             PropTypes.string,
  areDecksSortable:       PropTypes.bool,
  authenticityToken:      PropTypes.string,
  currentUser:            PropTypes.object,
  deck:                   PropTypes.object,
  isMobileViewportSize:   PropTypes.bool,
  isDraggable:            PropTypes.bool,
  isSelected:             PropTypes.bool,
  listNumber:             PropTypes.number,
  onDeckChange:           PropTypes.func,
  onDeckDetailRequest:    PropTypes.func,
  pack:                   PropTypes.object,
  studyMixPath:           PropTypes.string,
};


class DeckRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deckCards:                      [],
      isDeckEditable:                 false,
      isDeckRowHovered:               false,
      isPreviewCardsModalOpen:        false,
      isProcessingAddCardsAction:     false,
      isProcessingEditCardsAction:    false,
      isProcessingPreviewCardsAction: false,
      isProcessingUnlockDeckAction:   false,
      isShowNoCardsModalOpen:         false,
    };

    this.deckLockedIcon = null;
    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const deckStats = this.props.deck.stats;

    const mastery = deckStats.mastery;
    const masteryPercentage = Math.floor(mastery * 100) || 0;
    const masteryLevel = StudyHelper.getMasteryLevel(masteryPercentage);
    const masteryClass = `mastery-level-${masteryLevel}`;

    const isStudyableClass = (!this.props.deck.flags.isLocked) ? 'is-studyable' : '';
    const isLockedClass = (this.props.deck.flags.isLocked) ? 'is-locked' : ''

    let classes = toClassStr(['deck-row', isLockedClass, isStudyableClass, masteryClass, this.props.addClasses]);

    return (
      <li 
        className={classes} 
        id={`deck-${this.props.deck.deckId}`} 
        onClick={this.handleDeckRowClick}
        onMouseEnter={this.handleDeckRowMouseEnter}
        onMouseLeave={this.handleDeckRowMouseLeave}
      >
        <div className="deck-row-contents">

          {this.renderDragButton()}
          {this.renderRoundCheckboxOrLockIcon()}
          {this.renderMasteryOrLockIcon(masteryPercentage)}

          <div className="deck-info">
            <div className="deck-name-and-caption">
              <h4 className="deck-name" title={this.props.deck.name}>{this.props.deck.name}</h4>
              <p className="deck-name-caption" title={this.renderDeckNameCaptionText()}>{this.renderDeckNameCaptionText()}</p>
              {this.renderDeckObjectiveDynamicTooltipIcon()}
            </div>

            {this.renderBottomRow()}
          </div>

          {this.renderActionButtons()}
          {this.renderRemoveDeckButton()}
          {this.renderNoCardsModal()}
        </div>
      </li>
    );
  }

  renderDragButton() {
    if (this.props.pack.permission != 'admin') {
      return null;
    }

    if (this.props.isMobileViewportSize) {
      return null;
    }

    return (
      <DragButton
        addClasses="drag-card-button"
      />
    );
  }

  renderMasteryOrLockIcon(masteryPercentage) {
    if (this.props.deck.flags.isLocked) {
      return (
        <div className="deck-mastery">0%</div>
      );
    }

    return (
      <div className="deck-mastery">{masteryPercentage}%</div>
    );
  }

  renderRoundCheckboxOrLockIcon() {
    if (this.props.deck.flags.isLocked) {
      return (
        <div 
          className="deck-locked-icon" 
          ref={(elem) => { this.deckLockedIcon = elem }}
          onMouseEnter={this.handleLockIconMouseEnter}
          onMouseLeave={this.handleLockIconMouseLeave}
        ></div>
      );
    }

    return (
      <RoundCheckbox
        isChecked={this.props.isSelected}
        isDisabled={this.props.deck.cardIds.length < 1}
        value={this.props.deck.deckId}
        onClick={this.handleCheckboxClick}
        tooltipContent={this.renderCheckboxTooltipContent()}
        tooltipPosition="right"
      />
    );
  }

  renderCheckboxTooltipContent() {
    if (this.props.deck.stats.cardCount < 1) {
      return 'Add Cards before including in Study Mix';
    }

    return (this.props.isSelected) ? 'Remove from Study Mix' : 'Include in Study Mix';
  }

  renderDeckNameCaptionText() {
    const cardCount = this.props.deck.cardIds.length;
    const seenCount = this.props.deck.stats.seenCount;

    if (cardCount < 1) { return "There are no cards in this deck"; }
    if (this.props.deck.flags.isLocked) { return "Unlock this deck to study"; }

    const dispCardsStudied = NumberHelper.displayNumber(seenCount);
    const dispCardCount    = NumberHelper.displayNumber(cardCount);

    return `${dispCardsStudied} of ${dispCardCount} unique cards studied`;
  }

  renderDeckObjectiveDynamicTooltipIcon() {
    const deck = this.props.deck;
    const pack = this.props.pack;
    const areDecksEditable = pack.flags.areDecksEditable;
    let actions = null;

    if (this.props.isMobileViewportSize) {
      return null;
    }

    if (deck.desc) {

      if (areDecksEditable) {
        actions = [{
          label: 'Edit',
          tag: 'resolve',
          handler: this.handleEditObjectiveActionClick,
        }];
      }

      return (
        <DynamicTooltipIcon 
          actions={actions}
          addClasses="deck-objective-info-button"
          body={deck.desc}
          hasDismissButton={true}
          heading={deck.name}
          position="top"
          subHeading="Objective"
        />
      );
    }

    if (areDecksEditable) {
      actions = [{
        label: 'Enter an Objective',
        tag: 'resolve',
        handler: this.handleObjectivePromptActionClick,
      }];

      return (
        <DynamicTooltipIcon 
          actions={actions}
          addClasses="deck-objective-info-button"
          body="An effective way to communicate what knowledge a learner will cover in studying the flashcards in your Deck is to type in a short Objective which will be displayed instead of this text. (You are the only person who will see this tip)"
          hasDismissButton={true}
          heading="Did You Know?"
        />
      );
    }

    return null;
  }

  renderBottomRow() {
    if (this.props.isMobileViewportSize) {
      return (
        <div className="action-buttons bottom-row-action-buttons">
          {this.renderAddCardsButton()}
          {this.renderUnlockDeckButton()}
        </div>
      );
    }

    return this.renderProgressBar();
  }

  renderProgressBar() {
    const mastery = this.props.deck.stats.mastery;

    return (
      <SimpleProgressBar
        baseValue={100}
        barValue={mastery * 100}
        isLight={true}
        shouldDepictLevel={true}
      />
    );
  }

  renderActionButtons() {
    if (this.props.isMobileViewportSize) {
      return (
        <div className="action-buttons mobile-action-buttons">
          {this.renderDeckOptionsButton()}
          {this.renderStudyCardsButton()}
          {this.renderDeckDetailButton()}
        </div>
      );
    }

    return (
      <div className="action-buttons desktop-action-buttons">
        {this.renderUnlockDeckButton()}
        {this.renderPreviewCardsButton()}
        {this.renderEditCardsButton()}
        {this.renderBrowseCardsButton()}
        {this.renderDeckOptionsButton()}
        {this.renderAddCardsButton()}
        {this.renderStudyCardsButton()}
      </div>
    );
  }

  renderUnlockDeckButton() {
    if (!this.props.deck.flags.isLocked) { 
      return null; 
    }

    return (
      <PillButton
        addClasses="pill-button-small pill-button-emphasized action-button unlock-deck-button"
        isProcessing={this.state.isProcessingUnlockDeckAction}
        key="unlock-button"
        label="Unlock"
        onClick={this.handleUnlockDeckButtonClick}
      />
    );
  }

  renderAddCardsButton() {
    if (!this.props.deck.flags.isEditable || this.props.deck.cardIds.length > 0 || this.props.deck.flags.isLocked) {
      return null;
    }

    return (
      <PillButton
        addClasses="pill-button-small pill-button-emphasized action-button add-cards-button"
        isProcessing={this.state.isProcessingAddCardsAction}
        key="add-cards-button"
        label="Add Cards"
        onClick={this.handleAddCardsButtonClick}
      />
    );
  }

  renderPreviewCardsButton() {
    if (this.props.deck.cardIds.length < 1) {
      return null;
    }

    return (
      <PreviewButton
        addClasses="action-button preview-cards-button"
        isProcessing={this.state.isProcessingPreviewCardsAction}
        key="preview-button"
        onClick={this.handlePreviewCardsButtonClick}
        tooltipContent="Preview the Cards in this Deck"
        tooltipPosition="top"
      />
    );
  }

  renderEditCardsButton() {
    if (!this.props.deck.flags.isEditable) {
      return null;
    }

    return (
      <EditButton
        addClasses="action-button edit-cards-button"
        isProcessing={this.state.isProcessingEditCardsAction}
        key="edit-button"
        onClick={this.handleEditCardsButtonClick}
        tooltipContent="Edit the Cards in this Deck"
        tooltipPosition="top"
      />
    );
  }

  renderBrowseCardsButton() {
    if (this.props.deck.cardIds.length < 1) {
      return null;
    }

    return (
      <BrowseButton
        addClasses="action-button browse-cards-button"
        key="browse-button"
        onClick={this.handleBrowseCardsButtonClick}
        tooltipContent="Browse the Cards in this Deck"
        tooltipPosition="top"
      />
    );
  }

  renderDeckOptionsButton() {
    return (
      <DeckOptionsButton
        addClasses="action-button"
        currentDeck={this.props.deck}
        currentPack={this.props.pack}
        currentUser={this.props.currentUser}
        authenticityToken={this.props.authenticityToken}
        deck={this.props.deck}
        iconType="horizontal"
        isMobileViewportSize={this.props.isMobileViewportSize}
        key="deck-options-button"
        pack={this.props.pack}
        tooltipContent="Edit, Copy, Reset, more"
        tooltipPosition="top"
      />
    )
  }

  renderStudyCardsButton() {
    const isDisabled = this.props.deck.stats.cardCount < 1;

    return (
      <StudyButton
        addClasses="action-button study-cards-button"
        isDisabled={isDisabled}
        key="study-button"
        onClick={this.handleStudyButtonClick}
        tooltipContent="Study the Cards in this Deck"
        tooltipPosition="left"
      />
    );
  }

  renderRemoveDeckButton() {
    if (this.props.pack.permission != 'admin') {
      return null;
    }

    if (this.props.isMobileViewportSize) {
      return null;
    }

    return (
      <DismissButton
        addClasses="remove-deck-button"
        onClick={this.handleRemoveDeckButtonClick}
        tooltipContent="Remove Deck from Class"
        tooltipPosition="left"
      />
    );
  }


  renderDeckDetailButton() {
    return (
      <ForwardButton
        addClasses="action-button deck-detail-button"
        key="deck-detail-button"
        onClick={this.handleDeckDetailButtonClick}
        tooltipContent="View Deck Detail"
        tooltipPosition="top"
      />
    );
  }

  renderNoCardsModal() {
    return(
      <Modal
        addClasses="no-cards-modal"
        isOpen={this.state.isShowNoCardsModalOpen}
        onCloseRequest={this.handleNoCardsModalCloseRequest}
      >
        <div className="modal-title">This Deck Has No Cards</div>

        <p className="modal-message">Before you can study this deck, you or the Class creator must add cards to this deck.</p>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={this.handleNoCardsModalCloseRequest}
          />
        </div>
      </Modal>
    );
  }

  renderDeckSelectionCheckboxTooltip() {
    return (
      <div className="rich-content-tooltip">
        <p className="body-sub-heading">Select Deck</p>
        <p className="body-text">These decks will be included in your next Smart Study session, when you click the big blue button.</p>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddCardsButtonClick = () => {
    if (this.props.isMobileViewportSize || !this.props.currentUser.flags.features?.ai_phase_2) {
      this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId, null, 'edit');
      return true;
    }

    this.triggerMakeFlashcardsModalOpen();
  };

  handleBrowseCardsButtonClick = () => {
    this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId, null, 'browse');
  };

  handleCheckboxClick = () => {
    packDeckTransform.update('selections', {
      userId: this.props.currentUser.userId, 
      packId: this.props.pack.packId,
      deckIds: [this.props.deck.deckId],
      isSelected: !this.props.isSelected,
    });
  }

  handleDeckDetailButtonClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.deck.flags.isLocked) {
      this.triggerUnlockDeckModalOpen();
      return false;
    }

    this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId);
  };

  handleDeckRowClick = (e) => {
    e.stopPropagation();
    this.handleStudyButtonClick();
  };

  handleDeckRowMouseEnter = () => {
    this.setState({
      isDeckRowHovered: true
    });
  }

  handleDeckRowMouseLeave = () => {
    this.setState({
      isDeckRowHovered: false
    });
  }

  handleEditCardsButtonClick = () => {
    this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId, null, 'edit');
  };

  handleEditObjectiveActionClick = () => {
    this.triggerEditDeckInfoModalOpen();
  }

  handleNoCardsModalCloseRequest = () => {
    this.setState({isShowNoCardsModalOpen: false});
  };

  handleLegacyAddCardsButtonClick = () => {
    this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId, null, 'edit');
  };

  handleObjectivePromptActionClick = () => {
    this.triggerEditDeckInfoModalOpen();
  }

  handlePreviewCardsButtonClick = () => {
    this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId, null, 'preview');
  };

  handleRemoveDeckButtonClick = () => {
    const actionText = `remove the ${this.props.deck.name} deck from your class`;

    this.triggerConfirmModalOpen({
      actionText: actionText,
      resolveButtonText: 'Yes, remove deck',
      onResolution: () => {
        packDeck.destroy(this.props.pack.packId, this.props.deck.deckId);
      },
    });
  }

  handleStudyButtonClick = () => {
    const studyMixPath = this.props.studyMixPath;

    if (this.props.deck.flags.isLocked) {
      this.triggerUnlockDeckModalOpen();
      return false;
    }

    if (this.props.deck.stats.cardCount < 1) {
      this.setState({isShowNoCardsModalOpen: true});
      return false;
    }

    UiHelper.navigate(studyMixPath, 'Loading Study Experience');
  }

  handleUnlockDeckButtonClick = () => {
    this.setState({
      isProcessingUnlockDeckAction: true,
    });

    this.triggerUnlockDeckModalOpen(() => {
      this.setState({
        isProcessingUnlockDeckAction: false,
      });
    });
  };

  handleDeckSelectionCheckboxMouseEnter = () => {
    this.triggerDeckSelectionCheckboxTooltipOpen();
  }

  handleDeckSelectionCheckboxMouseLeave = () => {
    this.triggerDeckSelectionCheckboxTooltipClose();
  }

  handleLockIconMouseEnter = () => {
    this.triggerLockIconTooltipOpen();
  }

  handleLockIconMouseLeave = () => {
    this.triggerLockIconTooltipClose();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerDeckDetailViewRequest = (packId, deckId, cardId=null, tabId='preview') => {
    EventManager.emitEvent('deck-detail-view:change-request', {
      packId: packId,
      deckId: deckId,
      cardId: cardId,
      tabId: tabId,
    });
  }

  triggerDeckSelectionCheckboxTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      addClasses: 'deck-selection-checkbox-tooltip',
      content: this.renderDeckSelectionCheckboxTooltip(),
      elem: this.elem,
      position: 'right',
    });
  };

  triggerDeckSelectionCheckboxTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  triggerEditDeckInfoModalOpen = () => {
    EventManager.emitEvent('edit-deck-info-modal:open', {
      currentUser: this.props.currentUser,
      deck: this.props.deck,
      packId: this.props.pack.packId,
    });

    this.triggerTooltipClose({shouldCloseImmediately: true});
  };

  triggerMakeFlashcardsModalOpen = () => {   
    EventManager.emitEvent('make-flashcards-modal:open', {
      deckId: this.props.deck.deckId,
    });
  }

  triggerLockIconTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: 'Unlock this deck to study',
      elem: this.deckLockedIcon,
      position: 'right',
    });
  };

  triggerLockIconTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  triggerUnlockDeckModalOpen(onCloseRequestCallback) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: 'Unlock this deck',
      onCloseRequest: onCloseRequestCallback,
      pack: this.props.pack,
      paywall: (this.props.pack.isPremium ? 'unlock_cert_class' : 'unlock_UGC'),
      message: 'Upgrade to Brainscape Pro to gain access to all public and market flashcards on the entire platform.',
    });
  }

  triggerTooltipClose = (viewProps) => {
    EventManager.emitEvent('tooltip:close', viewProps);
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

DeckRow.propTypes = PT;

export default DeckRow;
