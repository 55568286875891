
// substrate and utils
import EventManager                       from '@brainscape/event-manager';
import PropTypes                          from 'prop-types';
import React                              from 'react';
import CookieHelper                       from '_utils/CookieHelper';
import SessionStorageHelper               from '_utils/SessionStorageHelper';
import UiHelper                           from '_utils/UiHelper';

import {
  createSubscription,
  removeSubscription,
}                                         from '_core/Cable';
                
// models
import cardClipboard                      from '_models/cardClipboard';
import deckCard                           from '_models/deckCard';
import deckCardReordering                 from '_models/deckCardReordering';
import packDeckTransform                  from '_models/packDeckTransform';
import userLocalStore                     from '_models/userLocalStore';

// concerns
import currentDeckConcern                 from '_concerns/currentDeckConcern';
import currentDeckCardsConcern            from '_concerns/currentDeckCardsConcern';
import currentPackConcern                 from '_concerns/currentPackConcern';
import trendingPacksConcern               from '_concerns/trendingPacksConcern';

// views
import DeckDetailPage                     from '_deck-detail/desktop/DeckDetailPage';
import DeckDetailScreen                   from '_deck-detail/mobile/DeckDetailScreen';


const PT = {  
  authenticityToken:            PropTypes.string,
  initialCardId:                PropTypes.node,
  initialDeckId:                PropTypes.node,
  initialPackId:                PropTypes.node,
  initialTabId:                 PropTypes.string,
  initialUser:                  PropTypes.object,
  initialUserPacks:             PropTypes.array,
  initialView:                  PropTypes.string,
  isLoadingUser:                PropTypes.bool,
  isLoadingUserPacks:           PropTypes.bool,
  isShowingCachedUserPacks:     PropTypes.bool,
  postRenderCallback:           PropTypes.func,
};

const VALID_TAB_IDS = ['preview', 'edit', 'browse'];


class DeckDetailController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardClipboard: null,
      currentCardId: props.initialCardId,
      currentDeck: {},
      currentDeckId: props.initialDeckId,
      currentPack: {},
      currentPackId: props.initialPackId,
      currentTabId: props.initialTabId || 'preview',
      isFtue: false,
      isLoadingDeck: true,
      isLoadingDeckCards: true,
      isLoadingPack: true,
      isLoadingTrendingPacks: false,
      isPaginatingDeckCards: false,
      isMobileSidebarOpen: false,
      isMobileViewportSize: null,
      isShowingCachedDeckCards: false,
      sidebarMode: null,
      trendingPacks: null,
    };

    this.events = new EventManager();

    this.cardClipboardStatusChannelSubscription = null;
    this.deckStatusChannelSubscription = null;

    this.postRenderCallbackTimeout = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.subscribeToEvents();
    this.manageViewport();
    this.initCurrentResources();
    this.manageFtue();
    this.startHistoryMonitor();
    this.startWindowResizeMonitor();
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialPackId != prevProps.initialPackId ) {
      this.setState({
        isLoadingDeck: true,
        isLoadingPack: true,
      });

      return true;
    }

    if (this.props.initialDeckId != prevProps.initialDeckId) {
      this.setState({
        isLoadingDeck: true,
      });

      return true;
    }

    if (prevProps.isFtue && !this.props.isFtue) {
      this.setState({
        isFtue: false,
      });
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this.stopWindowResizeMonitor();
    this.stopHistoryMonitor();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   INITIALIZE CORE RESOURCES
  ==================================================
  */

  initCurrentResources = () => {
    const packId = this.props.initialPackId;
    const deckId = this.props.initialDeckId;
    const cardId = this.props.initialCardId;

    this.initCurrentPackDeckAndCardData(packId, deckId, cardId);
    this.initTrendingPacksData();

    this.initSidebar();
    this.initCardClipboard();
  }

  initCurrentPackDeckAndCardData = (packId, deckId, cardId=null) => {
    this.initCurrentPackData(packId, deckId, cardId);
  }

  initCurrentPackData = (packId, deckId, cardId) => {
    currentPackConcern.get(packId).then(currentPackData => {
      const currentDeckId = deckId ? deckId : currentPackData.decks[0].deckId;
      this.initCurrentDeckData(packId, currentDeckId, cardId);
    }).catch(err => {
      console.error(err);
    });
  }

  initCurrentDeckData = (packId, deckId, cardId) => {
    currentDeckConcern.get(packId, deckId).then(() => {
      this.initCurrentDeckCardsData(packId, deckId, cardId);
    }).catch(err => {
      console.error(err);
    });
  }

  initCurrentDeckCardsData = (packId, deckId, cardId) => {
    currentDeckCardsConcern.get(packId, deckId, cardId).then(() => {
      this.pushResourceToHistory();
    }).catch(err => {
      console.error(err);
    });
  }

  initTrendingPacksData = () => {
    trendingPacksConcern.fetch()
      .catch(err => {
        console.error(err);
        this.handleTrendingPacksError(err);
      });
  }

  initSidebar = () => {
    this.setState({
      sidebarMode: this.getSidebarMode(),
    })
  }

  initCardClipboard = () => {
    cardClipboard.show().then(clipboardData => {
      this.setState({
        cardClipboard: clipboardData.clipboard,
      });
    }).catch(err => {
      console.error(err);
    });
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('card:add-new-card-request',          this.handleAddNewCardRequest);
    this.events.addListener('card:created',                       this.handleCardCreated);
    this.events.addListener('card:dismiss-new-card-request',      this.handleDismissNewCardRequest);
    this.events.addListener('card:duplicated',                    this.handleCardDuplicated);
    this.events.addListener('card:insert-new-card-request',       this.handleInsertNewCardRequest);
    this.events.addListener('card:inserted',                      this.handleCardInserted);
    this.events.addListener('card:removed',                       this.handleCardRemoved);
    this.events.addListener('card:updated',                       this.handleCardUpdated);
    this.events.addListener('card-attachment:job-created',        this.handleCardAttachmentJobCreated);
    this.events.addListener('card-attachment:job-completed',      this.handleCardAttachmentJobCompleted);
    this.events.addListener('card-clipboard:cards-copied',        this.handleCardClipboardCardsCopied);
    this.events.addListener('card-clipboard:cards-pasted',        this.handleCardClipboardCardsPasted);
    this.events.addListener('card-clipboard:cleared',             this.handleCardClipboardCleared);
    this.events.addListener('card-confidence:updated',            this.handleCardConfidenceUpdated);
    this.events.addListener('card-reversal-job:completed',        this.handleCardReversalJobCompleted);
    this.events.addListener('cards:import-success-toast-open',    this.handleCardsImportSuccess);
    this.events.addListener('category-subscription:created',      this.handleCategorySubscriptionCreated);
    this.events.addListener('current-card:change-request',        this.handleCurrentCardChangeRequest);
    this.events.addListener('current-card:next-card-request',     this.handleCurrentCardNextCardRequest);
    this.events.addListener('current-card:prev-card-request',     this.handleCurrentCardPrevCardRequest);
    this.events.addListener('current-deck:change-request',        this.handleCurrentDeckChangeRequest);
    this.events.addListener('current-tab:change-request',         this.handleCurrentTabChangeRequest);
    this.events.addListener('deck:created',                       this.handleDeckCreated);
    this.events.addListener('deck:imported',                      this.handleDeckImported);
    this.events.addListener('deck:removed',                       this.handleDeckRemoved);
    this.events.addListener('deck:retrieved',                     this.handleDeckRetrieved);
    this.events.addListener('deck:updated',                       this.handleDeckUpdated);
    this.events.addListener('deck-cards:generated',               this.handleDeckCardsGenerated);
    this.events.addListener('deck-cards:page-received',           this.handleDeckCardsPageReceived);
    this.events.addListener('deck-cards:pagination-completed',    this.handleDeckCardsPaginationCompleted);
    this.events.addListener('deck-cards:reorder-request',         this.handleDeckCardsReorderRequest);
    this.events.addListener('deck-cards:reordered',               this.handleDeckCardsReordered);
    this.events.addListener('deck-cards:removed',                 this.handleDeckCardsRemoved);
    this.events.addListener('deck-cards:retrieved',               this.handleDeckCardsRetrieved);
    this.events.addListener('deck-cards:sorted',                  this.handleDeckCardsSorted);
    this.events.addListener('deck-confidences:reset',             this.handleDeckConfidencesReset);
    this.events.addListener('deck-csv-export:job-created',        this.handleDeckCsvExportJobCreated);
    this.events.addListener('deck-csv-export:job-completed',      this.handleDeckCsvExportJobCompleted);
    this.events.addListener('deck-csv-import:job-created',        this.handleDeckCsvImportJobCreated);
    this.events.addListener('deck-csv-import:job-completed',      this.handleDeckCsvImportJobCompleted);
    this.events.addListener('pack:retrieved',                     this.handlePackRetrieved);
    this.events.addListener('pack:updated',                       this.handlePackUpdated);
    this.events.addListener('sidebar:mode-change-request',        this.handleSidebarModeChangeRequest);
    this.events.addListener('trending-packs:retrieved',           this.handleTrendingPacksRetrieved);
    this.events.addListener('user-pref:updated',                  this.handleUserPrefUpdated);

    // web socket channel subscriptions
    this.cardClipboardStatusChannelSubscription = createSubscription(
      {channel: 'CardClipboardStatusChannel', userId: this.props.initialUser.userId},
      {received: this.handleCardClipboardStatusChannelUpdate},
    );

    this.deckStatusChannelSubscription = createSubscription(
      {channel: 'DeckStatusChannel', deckId: this.props.initialDeckId},
      {received: this.handleDeckStatusChannelUpdate},
    );
  }

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
      removeSubscription(this.cardClipboardStatusChannelSubscription);
      removeSubscription(this.deckStatusChannelSubscription);
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    // if (this.state.isMobileViewportSize) {
    //   return this.renderMobileScreen();
    // }

    return this.renderDesktopPage();
  }

  renderDesktopPage() {

    return (
      <div>
        <DeckDetailPage
          cardClipboard={this.state.cardClipboard}
          currentCardId={this.state.currentCardId}
          currentDeck={this.state.currentDeck}
          currentDeckId={this.state.currentDeckId}
          currentPack={this.state.currentPack}
          currentPackId={this.state.currentPackId}
          currentUser={this.props.initialUser}
          currentUserPacks={this.props.initialUserPacks}
          currentTabId={this.state.currentTabId}
          handleHideClassesRequest={() => this.triggerMobileClassesOverlayClose()}
          handleShowClassesRequest={() => this.triggerMobileClassesOverlayOpen()}
          isFtue={this.state.isFtue}
          isLoadingDeck={this.state.isLoadingDeck}
          isLoadingDeckCards={this.state.isLoadingDeckCards}
          isLoadingPack={this.state.isLoadingPack}
          isLoadingTrendingPacks={this.state.isLoadingTrendingPacks}
          isLoadingUser={this.props.isLoadingUser}
          isLoadingUserPacks={this.props.isLoadingUserPacks}
          isPaginatingDeckCards={this.state.isPaginatingDeckCards}
          isMobileViewportSize={this.state.isMobileViewportSize}
          isMobileSidebarOpen={this.state.isMobileSidebarOpen}
          isShowingCachedDeckCards={this.state.isShowingCachedDeckCards}
          isShowingCachedUserPacks={this.props.isShowingCachedUserPacks}
          sidebarMode={this.state.sidebarMode}
          trendingPacks={this.state.trendingPacks}
        />

      </div>
    );
  }

  renderMobileScreen() {
    return (
      <DeckDetailScreen
        cardClipboard={this.state.cardClipboard}
        currentCardId={this.state.currentCardId}
        isLoadingDeck={this.state.isLoadingDeck}
        isLoadingDeckCards={this.state.isLoadingDeckCards}
        currentPack={this.state.currentPack}
        currentUser={this.props.initialUser}
        currentUserPacks={this.props.initialUserPacks}
        currentTabId={this.state.currentTabId}
        isLoadingPack={this.state.isLoadingPack}
        isLoadingTrendingPacks={this.state.isLoadingTrendingPacks}
        isLoadingUser={this.props.isLoadingUser}
        isLoadingUserPacks={this.props.isLoadingUserPacks}
        isPaginatingDeckCards={this.state.isPaginatingDeckCards}
        isShowingCachedDeckards={this.state.isShowingCachedDeckCards}
        isShowingCachedUserPacks={this.props.isShowingCachedUserPacks}
        sidebarMode={this.state.sidebarMode}
        trendingPacks={this.state.trendingPacks}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddNewCardRequest = (eventData) => {
    const opts = {
      card: {},
      contentType: 'md',
      deckId: this.state.currentDeckId,
      editMode: eventData.editMode,
      packId: this.state.currentPackId,
    };

    deckCard.create(opts);
  }

  handleCardsImportSuccess = (eventData) => {
    this.triggerToastOpen(eventData.message, 'success', 3000);
  }

  handleInsertNewCardRequest = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const opts = {
      card: {},
      contentType: 'md',
      deck: currentDeck,
      editMode: eventData.editMode,
      packId: currentPackId,
      prevCardId: eventData.cardId,
    };

    deckCard.insert(opts);
  }

  handleCardAttachmentJobCreated = (eventData) => {
    console.log('in handleCardAttachmentJobCreated. eventData:', eventData);
  }

  handleCardAttachmentJobCompleted = (eventData) => {
    console.log('in handleCardAttachmentJobCompleted. eventData:', eventData);
  }

  handleCardClipboardCardsCopied = (eventData) => {
    this.setState({
      cardClipboard: eventData,
    }, () => {
      this.triggerToastOpen('Cards copied for pasting');
    });
  }

  handleCardClipboardCardsPasted = (eventData) => {
    if (!(eventData.packId == this.state.currentPackId && eventData.deckId == this.state.currentDeckId)) {
      this.handleCardClipboardCleared();
      return false;
    }

    const currentDeckData = currentDeckCardsConcern.handleCardClipboardCardsPasted(this.state.currentPackId, this.state.currentDeck, eventData);

    if (currentDeckData) {
      this.setState({
        cardClipboard: null,
        currentCardId: currentDeckData.currentCardId,
        currentDeck: {...this.state.currentDeck, ...currentDeckData.currentDeck},
        currentDeckId: currentDeckData.currentDeckId,
      }, () => {
        this.triggerToastOpen('Cards pasted');
        this.handleCardClipboardCleared();
      });
    }
  }

  handleCardClipboardCleared = () => {
    this.setState({
      cardClipboard: null,
    });
  }

  handleCardClipboardStatusChannelUpdate = (eventData) => {
    if ((eventData.packId == this.state.currentPackId && eventData.deckId == this.state.currentDeckId)) {
      // ignore this second capture of an event that is being handled directly in this document instance
      return false;
    }

    switch (eventData.action) {
      case 'copy':
        if ((eventData.srcPackId == this.state.currentPackId && eventData.srcDeckId == this.state.currentDeckId)) {
          // ignore this second capture of an event that is being handled directly in this document instance
          return false;
        }

        this.handleCardClipboardCardsCopied({
          action: 'copy',
          cardIds: eventData.srcCardIds,
          deckId: eventData.srcDeckId,
          packId: eventData.srcPackId,
        });
      break;

      case 'paste':
        // we don't need to do anything in this particular document instance except clear the paste UI
        this.handleCardClipboardCleared();
      break;

      case 'clear':
        this.handleCardClipboardCleared();
      break;
    }
  }

  handleCardConfidenceUpdated = (eventData) => {
    const {currentPack, currentDeck, currentPackId, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentPackData = currentPackConcern.handleCardConfidenceUpdated(currentPack, eventData);
    const currentDeckCardsData = currentDeckCardsConcern.handleCardConfidenceUpdated(currentPackId, currentDeck, eventData);

    const updatedPack = currentPackData ? {...currentPack, ...currentPackData.currentPack} : currentPack;
    const updatedDeck = currentDeckCardsData ? {...currentDeck, ...currentDeckCardsData.currentDeck} : currentDeck;

    if (currentPackData || currentDeckCardsData) {
      this.setState({
        currentDeck: updatedDeck,
        currentPack: updatedPack,
      });
    }
  }

  handleCardCreated = (eventData) => {
    const {currentPack, currentPackId, currentDeck, currentDeckId, currentCardId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const isFirstCardInDeck = (currentDeck.stats?.cardCount == 0);

    if (isFirstCardInDeck) {
      this.markDeckAsSelected(currentPackId, currentDeckId, this.props.initialUser.userId)
    }

    const currentPackData = currentPackConcern.handleCardCreated(currentPack, eventData);
    const currentDeckCardsData = currentDeckCardsConcern.handleCardCreated(currentPackId, currentDeck, currentCardId, eventData);

    const updatedPack = currentPackData ? {...currentPack, ...currentPackData.currentPack} : currentPack;
    const updatedDeck = currentDeckCardsData ? {...currentDeck, ...currentDeckCardsData.currentDeck} : currentDeck;

    if (currentPackData || currentDeckCardsData) {
      this.setState({
        currentCardId: currentDeckCardsData.currentCardId,
        currentDeck: updatedDeck,
        currentDeckId: currentDeckCardsData.currentDeckId,
        currentPack: updatedPack,
      }, () => {
        this.triggerToastOpen('Card created', 'success');
      });
    }
  }

  handleCardDuplicated = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentDeckCardsData = currentDeckCardsConcern.handleCardDuplicated(currentPackId, currentDeck, eventData);

    if (currentDeckCardsData) {
      this.setState({
        currentCardId: currentDeckCardsData.currentCardId,
        currentDeck: {...this.state.currentDeck, ...currentDeckCardsData.currentDeck},
        currentDeckId: currentDeckCardsData.currentDeckId,
      }, () => {
        this.triggerToastOpen('Card duplicated', 'success');
      });
    }
  }

  handleCardInserted = (eventData) => {
    const {currentPack, currentPackId, currentDeck, currentDeckId, currentCardId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentPackData = currentPackConcern.handleCardInserted(currentPack, eventData);
    const currentDeckCardsData = currentDeckCardsConcern.handleCardInserted(currentPackId, currentDeck, currentCardId, eventData);

    const updatedPack = currentPackData ? {...currentPack, ...currentPackData.currentPack} : currentPack;
    const updatedDeck = currentDeckCardsData ? {...currentDeck, ...currentDeckCardsData.currentDeck} : currentDeck;

    if (currentPackData || currentDeckCardsData) {
      this.setState({
        currentCardId: currentDeckCardsData.currentCardId,
        currentDeck: updatedDeck,
        currentDeckId: currentDeckCardsData.currentDeckId,
        currentPack: updatedPack,
      }, () => {
        this.triggerToastOpen('Card inserted', 'success');
      });
    }
  }

  handleCardRemoved = (eventData) => {    
    const {currentPackId, currentPack, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentPackData = currentPackConcern.handleCardRemoved(currentPack, eventData);
    const currentDeckCardsData = currentDeckCardsConcern.handleCardRemoved(currentPackId, currentDeck, eventData);

    const updatedPack = currentPackData ? {...currentPack, ...currentPackData.currentPack} : currentPack;
    const updatedDeck = currentDeckCardsData ? {...currentDeck, ...currentDeckCardsData.currentDeck} : currentDeck;

    if (currentPackData || currentDeckCardsData) {
      this.setState({
        currentCardId: currentDeckCardsData.currentCardId,
        currentDeck: updatedDeck,
        currentDeckId: currentDeckCardsData.currentDeckId,
        currentPack: updatedPack,
      }, () => {
        this.triggerToastOpen('Card removed', 'success');
        this.pushResourceToHistory();
      });
    }
  }

  handleCardReversalJobCompleted = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentDeckData = currentDeckCardsConcern.handleCardReversalJobCompleted(currentPackId, currentDeck, eventData);

    if (currentDeckData) {
      this.setState({
        currentCardId: currentDeckData.currentCardId,
        currentDeck: {...this.state.currentDeck, ...currentDeckData.currentDeck},
        currentDeckId: currentDeckData.currentDeckId,
      }, () => {
        this.triggerToastOpen('Card reversal completed');
      });
    }
  }

  handleCardUpdated = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentDeckData = currentDeckCardsConcern.handleCardUpdated(currentPackId, currentDeck, eventData);
   
    if (currentDeckData) {
      this.setState({
        currentDeck: {...this.state.currentDeck, ...currentDeckData.currentDeck},
        currentDeckId: currentDeckData.currentDeckId,
      }, () => {
        this.triggerToastOpen('Card updated', 'success');
      });
    }
  }

  handleCategorySubscriptionCreated = (eventData) => {  
    const category = eventData.category;

    this.setState({
      currentPackId: category.packId,
    }, () => {
      const packPath = `/l/dashboard/${this.state.currentPackId}`;
      UiHelper.navigate(packPath);
    });
  }

  handleCurrentCardChangeRequest = (eventData) => {
    this.setState({
      currentCardId: eventData.cardId,
      currentTabId: eventData.tabId || this.state.currentTabId || 'preview',
    }, () => {
      this.pushResourceToHistory();
    });
  }

  handleCurrentCardNextCardRequest = (eventData) => {
    const referenceCardId = eventData.cardId;
    const currentDeckCardIds = this.state.currentDeck.cardIds;

    if (!referenceCardId) {
      return false;
    }

    const referenceCardIndex = currentDeckCardIds.findIndex(cardId => cardId == referenceCardId);

    if (referenceCardIndex == -1 || referenceCardIndex + 1 >= currentDeckCardIds.length) {
      this.handleAddNewCardRequest({
        editMode: eventData?.editMode,
      });
      return true;
    }

    this.setState({
      currentCardId: currentDeckCardIds[referenceCardIndex + 1],
    }, () => {
      this.pushResourceToHistory();
    });
  }

  handleCurrentCardPrevCardRequest = (eventData) => {
    const referenceCardId = eventData.cardId;
    const currentDeckCardIds = this.state.currentDeck.cardIds;

    if (!referenceCardId) {
      return false;
    }

    const referenceCardIndex = currentDeckCardIds.indexOf(referenceCardId);

    if (referenceCardIndex <= 0) {
      return false;
    }

    this.setState({
      currentCardId: currentDeckCardIds[referenceCardIndex - 1],
    }, () => {
      this.pushResourceToHistory();
    });
  }

  handleCurrentDeckChangeRequest = (eventData) => {
    this.setState({
      currentCardId: eventData.cardId,
      currentDeckId: eventData.deckId,
      currentTabId:  eventData.tabId || this.currentTabId || 'preview',
      isLoadingDeck: true,
      isLoadingDeckCards: true,
    }, () => {
      const {currentPackId, currentDeckId, currentCardId} = this.state;
      this.initCurrentDeckData(currentPackId, currentDeckId, currentCardId);
      this.resetDeckStatusChannelSubscription();
    });
  }

  handleCurrentTabChangeRequest = (eventData) => {
    this.setCurrentTabById(eventData.tabId);
  }

  handleDeckCardsGenerated = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const originalLastCardId = currentDeck.cardIds.at(-1);
    const originalLastCardIndex = currentDeck.cardIds.length - 1;
    const isPaginated = false;

    this.setState({
      isLoadingDeckCards: true,
    });

    deckCard.index(currentPackId, currentDeckId, isPaginated).then((cardsData) => {
      const currentDeckCardsData = currentDeckCardsConcern.handleCardsGenerated(currentPackId, currentDeck, cardsData);

      const updatedDeck = currentDeckCardsData ? {...currentDeck, ...currentDeckCardsData.currentDeck} : currentDeck;
      const currentCardId = updatedDeck.cardIds[originalLastCardIndex + 1] || originalLastCardId;

      if (currentDeckCardsData) {
        this.setState({
          currentCardId: currentCardId,
          currentDeck: updatedDeck,
          isLoadingDeckCards: false,
        }, () => {
          this.postRenderCallbackTimeout = setTimeout(() => {
            this.triggerToastOpen(`${eventData.newCardCount} Cards Generated and Added to Deck`, 'success', 5000);
            clearTimeout(this.postRenderCallbackTimeout);
          }, 2000);
        });
      }
    });
  }

  handleDeckCardsPageReceived = (eventData) => {
    /*
      NOTE: Deck Cards are auto-paginated. This method receives each page of the entire set. As soon as we get the first page of Deck Cards, we update the 'above the fold' UI. The newModel.paginatedIndex continues to retrieve any remaining pages in the background. When all pages of the index are received, the paginatedIndex promise will resolve with a set of all DeckCards, at which time we will finish display below the fold with the 'handleDeckRetrieved' method.
    */

    if (this.state.isShowingCachedDeckCards) {
      return false;
    }

    if (eventData.page > 1) {
      // in the current UI, we take page 1 and render to the user. We wait to receive the whole set before rendering again.
      return false;
    }

    const currentDeck = {...this.state.currentDeck};
    currentDeck.cards = eventData.cards;
    currentDeck.cardIds = currentDeck.cards.map(card => card.cardId);

    const isPaginatingDeckCards = (eventData.page < eventData.totalPages);

    this.setState({
      currentDeck: currentDeck,
      currentDeckId: currentDeck.deckId,
      isPaginatingDeckCards: isPaginatingDeckCards,
    });
  }

  handleDeckCardsPaginationCompleted = (eventData) => {
     this.setState({
       isPaginatingDeckCards: false,
     });
  }

  handleDeckCardsRemoved = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentDeckData = currentDeckCardsConcern.handleDeckCardsRemoved(currentPackId, currentDeck, eventData);

    if (currentDeckData) {
      this.setState({
        currentCardId: currentDeckData.currentCardId,
        currentDeck: {...this.state.currentDeck, ...currentDeckData.currentDeck},
        currentDeckId: currentDeckData.currentDeckId,
      }, () => {
        this.triggerToastOpen('Cards removed', 'success');
        this.pushResourceToHistory();
      });
    }
  }

  handleDeckCardsReordered = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentDeckData = currentDeckCardsConcern.handleDeckCardsReordered(currentPackId, currentDeck, eventData);

    if (currentDeckData) {
      this.setState({
        currentDeck: {...this.state.currentDeck, ...currentDeckData.currentDeck},
        currentDeckId: currentDeckData.currentDeckId,
      }, () => {
        this.triggerToastOpen('Cards reordered', 'success');
        this.pushResourceToHistory();
      });
    }
  }

  handleDeckCardsReorderRequest = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentDeckData = currentDeckCardsConcern.handleDeckCardsReorderRequest(currentPackId, currentDeck, eventData);

    if (currentDeckData) {
      this.setState({
        currentCardId: eventData.sortedCardId || this.state.currentCardId,
        currentDeck: {...this.state.currentDeck, ...currentDeckData.currentDeck},
        currentDeckId: currentDeckData.currentDeckId,
      }, () => {
        deckCardReordering.create(currentPackId, currentDeckId, eventData.reorderedCardIds);
      });
    }
  }

  handleDeckCardsRetrieved = (eventData) => {
    /*
      NOTE: Deck Cards are auto-paginated. This method receives either: first, a cached copy of all Deck Cards (if it is available in Session Storage), and then, in either case, a copy of all Deck Cards (after pagination is complete). If there is no cached copy available, the UI will be updated with the first page of Deck Cards via the 'handleDeckCardsPageReceived' method which receives each paginated page from the server.
    */

    const {currentPackId, currentDeck, currentDeckId, currentCardId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    let currentCard = null;
    let currentCardIndex = null;

    const updatedDeck = {...currentDeck};
    updatedDeck.cards = eventData.cards;
    updatedDeck.cardIds = updatedDeck.cards.map(card => card.cardId);

    if (updatedDeck.cardIds?.length > 0) {
      currentCardIndex = (currentCardId) ? Math.max(updatedDeck.cardIds.indexOf(currentCardId), 0) : 0;
      currentCard = updatedDeck.cards[currentCardIndex];
    }

    this.setState({
      currentCardId: currentCard?.cardId || null,
      currentDeck: updatedDeck,
      currentDeckId: updatedDeck.deckId,
      isLoadingDeck: false,
      isLoadingDeckCards: false,
      isPaginatingDeckCards: false,
      isShowingCachedDeckCards: eventData.isFromCache,
    }, () => {
      this.triggerCurrentCardScrollToRequest();
      this.handlePageRendered();
    });
  }

  handleDeckCardsSorted = (eventData) => {
    this.handleDeckCardsReordered(eventData);
  }

  handleDeckConfidencesReset = (eventData) => {
    const {packId, deckId} = eventData;

    if (packId == this.state.currentPackId) {
      this.initCurrentPackDeckAndCardData(packId, deckId);
      this.triggerToastOpen('Deck stats reset');
    }
  }

  handleDeckCreated = (eventData) => {
    const {packId, deckId} = eventData;

    if (eventData.packId == this.state.currentPackId) {
      this.setState({
        currentDeckId: deckId,
      }, () => {
        this.initCurrentPackDeckAndCardData(packId, deckId);
      });
    }
  }

  handleDeckImported = (eventData) => {
    const {packId} = eventData;

    if (eventData.packId == this.state.currentPackId) {
      this.initCurrentPackDeckAndCardData(packId, this.state.currentDeckId);
    }
  }

  handleDeckCsvExportJobCreated = (eventData) => {
    this.triggerToastOpen('Export to CSV started');
  }

  handleDeckCsvExportJobCompleted = (eventData) => {
    this.triggerToastOpen('Export to CSV finished. Check the Slack Conversion channel for your CSV file', 'success', 10000);
  }

  handleDeckCsvImportJobCreated = (eventData) => {
    this.triggerToastOpen('Import from CSV started');
  }

  handleDeckCsvImportJobCompleted = (eventData) => {
    this.triggerToastOpen('Import from CSV finished. Refresh your screen to view your imported cards', 10000);
  }

  handleDeckRemoved = (eventData) => {
    if (eventData.packId == this.state.currentPackId && eventData.deckId == this.state.currentDeckId) {
      UiHelper.navigate(this.state.currentPack.paths.dashboardPath, 'Loading Class Page...')
    }
  }

  handleDeckRetrieved = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentDeckData = currentDeckConcern.handleDeckRetrieved(currentPackId, currentDeck, eventData);

    if (currentDeckData) {
      this.setState({
        currentDeck: {...this.state.currentDeck, ...currentDeckData.currentDeck},
        currentDeckId: currentDeckData.currentDeckId,
        isLoadingDeck: false,
      });
    }
  }

  handleDeckStatusChannelUpdate = (eventData) => {
    // console.log('in handleDeckStatusChannelUpdate. eventData:', eventData);
  }

  handleDeckUpdated = (eventData) => {
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const currentDeckData = currentDeckConcern.handleDeckUpdated(currentPackId, currentDeck, eventData);

    if (currentDeckData) {
      this.setState({
        currentDeck: {...this.state.currentDeck, ...currentDeckData.currentDeck},
        currentDeckId: currentDeckData.currentDeckId,
      }, () => {
        this.triggerToastOpen('Deck updated', 'success');
      });
    }
  }

  handleDismissNewCardRequest = (eventData) => {
    this.handleCardRemoved(eventData);
  }

  handlePackRetrieved = (eventData) => {
    const currentPack = {...this.state.currentPack, ...eventData.currentPack};
    const currentPackId = currentPack.packId;

    this.setState({
      currentPack: currentPack,
      currentPackId: currentPackId,
      isLoadingPack: false,
    });
  }

  handlePackUpdated = (eventData) => {
    const currentPackId = this.state.currentPackId;

    if (!(eventData.packId == currentPackId)) {
      return false;
    }

    const currentPackData = currentPackConcern.handlePackUpdated(currentPackId, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
        currentPackId: currentPackData.currentPackId,
      }, () => {
        this.triggerToastOpen('Class updated', 'success');
      });
    }
  }

  handlePageRendered = () => {
    if (this.props.postRenderCallback) {
      this.postRenderCallbackTimeout = setTimeout(() => {
        this.props.postRenderCallback();
        clearTimeout(this.postRenderCallbackTimeout);
      }, 2000);
    }
  }

  handleSidebarModeChangeRequest = ({sidebarMode}) => {
    this.setState({
      sidebarMode: sidebarMode,
    });

    userLocalStore.setUserLocalPref(this.props.initialUser.userId, 'dashboardSidebarModePref', sidebarMode);
  }

  handleTrendingPacksError = (err) => {
    this.setState({
      isLoadingTrendingPacks: false,
      trendingPacks: [],
    });
  };

  handleTrendingPacksRetrieved = (eventData) => {
    this.setState({
      isLoadingTrendingPacks: false,
      trendingPacks: eventData.trendingPacks,
    });
  }

  handleUserPrefUpdated = (prefData) => {
    switch (prefData.prefKey) {
    }
  }

  handleWindowResize = (e) => {
    this.manageViewport();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCurrentCardScrollToRequest = () => {
    EventManager.emitEvent('current-card:scroll-to-request', {});
  }

  triggerCurrentPackScrollToRequest = () => {
    EventManager.emitEvent('current-pack:scroll-to-request', {});
  }

  triggerDeckDetailViewRequest = (packId, deckId, cardId=null, tabId='preview') => {
    EventManager.emitEvent('deck-detail-view:change-request', {
      packId: packId,
      deckId: deckId,
      cardId: cardId,
      tabId: tabId,
    });
  }

  triggerMobileClassesOverlayClose = () => {
    this.setState(
      {
        isMobileSidebarOpen: false,
      }
    );
  }

  triggerMobileClassesOverlayOpen = () => {
    this.setState(
      {
        isMobileSidebarOpen: true,
        sidebarMode: 'full',
      },
      () => {
        this.scrollSidebarToCurrentPack();
      },
    );
  }

  triggerPackDetailViewRequest = (packId, deckId=null, cardId=null, tabId='decks') => {
    EventManager.emitEvent('pack-detail-view:change-request', {
      packId: packId,
      deckId: deckId,
      cardId: cardId,
      tabId: tabId,
    });
  }

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type='success', duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.postRenderCallbackTimeout);
  }

  getSidebarMode = () => {
    if (this.props.initialUser?.flags?.isFtue) {
      return 'mini';
    }

    if (this.props.initialUser?.userId) {
      const localPrefMode = userLocalStore.getUserLocalPref(this.props.initialUser.userId, 'dashboardSidebarModePref',
      );

      if (localPrefMode) {
        return localPrefMode;
      }
    }

    const cookieMode = CookieHelper.getCookie('dashboard_sidebar_mode');

    if (cookieMode) {
      return cookieMode;
    }

    return 'full';
  }
  
  hasArrayData = (resource) => {
    return (resource && !this.isEmptyArray(resource));
  }

  hasObjectData = (resource) => {
    return (resource && !this.isEmptyObject(resource));
  }

  isEmptyArray = (arr) => {
    return (arr.length == 0);
  }

  isEmptyObject = (obj) => {
    return (Object.keys(obj).length == 0);
  }

  manageFtue = () => {
    if (this.props.isFtue) {
      this.setState({
        isFtue: true,
      });
    }
  }

  manageViewport = () => {
    UiHelper.adjustViewportHeight();
    const isMobileViewportSize = UiHelper.detectIfMobileSize();

    this.setState({
      isMobileViewportSize: isMobileViewportSize,
    });
  }

  markDeckAsSelected = (packId, deckId) => {
    packDeckTransform.update('selections', {
      userId: this.props.initialUser.userId, 
      packId: packId,
      deckIds: [deckId],
      isSelected: true,
    });
  }

  popResourceFromHistory = (e) => {
    if (!this._isMounted) {
      return false;
    }

    e.stopPropagation();

    const historyObj = e.state;

    if (historyObj.packId != this.state.currentPackId) {
      const {packId, deckId, cardId, tabId} = historyObj;
      this.triggerPackDetailViewRequest(packId, deckId, cardId, tabId);

      return historyObj.path;
    }

    if (!historyObj.deckId) {
      const {packId, tabId} = historyObj;
      const deckId = null;
      const cardId = null;

      this.triggerPackDetailViewRequest(packId, deckId, cardId, tabId);

      return historyObj.path;
    }    

    if (historyObj?.deckId != this.state.currentDeckId) {
      const {packId, deckId, cardId, tabId} = historyObj;

      const eventData = {
        packId: packId,
        deckId: deckId,
        cardId: cardId,
        tabId: tabId,
      };

      this.handleCurrentDeckChangeRequest(eventData);

      return historyObj.path;
    }

    if (historyObj.cardId != this.state.currentCardId) {
      const eventData = {
        cardId: historyObj.cardId,
        tabId: historyObj.tabId,
      };

      this.handleCurrentCardChangeRequest(eventData);

      return historyObj.path;
    }

    if (historyObj.tabId != this.state.currentTabId) {
      const eventData = {
        tabId: historyObj.tabId,
      };

      this.handleCurrentTabChangeRequest(eventData);

      return historyObj.path;
    }

    return null;
  };

  pushResourceToHistory = () => {
    if (!this._isMounted) {
      return false;
    }

    const pack = this.state.currentPack;
    const search = window.location.search;
    const deckId = this.state.currentDeckId;
    const cardId = this.state.currentCardId;
    const tabId = this.state.currentTabId;

    let path;

    if (cardId) {
      path = `${pack.paths.dashboardPath}/decks/${deckId}/cards/${cardId}/${tabId}`;
    } else {
      path = `${pack.paths.dashboardPath}/decks/${deckId}/${tabId}`;
    }

    const currentPath = window.history?.state?.path;

    if (path != currentPath) {
      window.history.pushState({
        packId: pack ? pack.packId : null,
        deckId: deckId || null,
        cardId: cardId || null,
        tabId: tabId,
        path: path,
      }, null, path);
    }
  }

  resetDeckStatusChannelSubscription = () => {
    removeSubscription(this.deckStatusChannelSubscription);

    this.deckStatusChannelSubscription = createSubscription(
      {channel: 'DeckStatusChannel', deckId: this.state.currentDeckId},
      {received: this.handleDeckStatusChannelUpdate},
    );
  }

  scrollSidebarToCurrentPack() {
    const currentSidebarPack = document.querySelector(
      '.dashboard-sidebar .sidebar-pack.is-selected',
    );

    if (currentSidebarPack) {
      currentSidebarPack.scrollIntoView();
    }
  }

  selectAdjacentPack = (referencePackId) => {
    const userPacks = this.props.initialUserPacks;
    const packCount = userPacks.length;

    const referenceIndex = userPacks.findIndex(userPack => {
      return (userPack.packId == referencePackId);
    });

    const adjacentIndex = (referenceIndex == 0) ? 1 : Math.min(packCount - 2, Math.max(0, referenceIndex - 1));
    const adjacentPack = userPacks[adjacentIndex];

    this.setCurrentPack(adjacentPack);
  }

  setCurrentPack = (pack) => {
    this.triggerPackDetailViewRequest(pack.packId);
  }

  setCurrentPackAndDeckByIds = (packId, deckId, cardId=null, tabId='preview') => {
    this.triggerDeckDetailViewRequest(packId, deckId, cardId, tabId);
  }

  setCurrentPackById = (packId, tabId='decks') => {
    this.triggerPackDetailViewRequest(packId, tabId);
  }

  setCurrentTabById = (tabId) => {
    const currentTabId = this.verifyCurrentTabId(tabId);

    this.setState({
      currentTabId: currentTabId,
    }, () => {
      this.pushResourceToHistory();

      if (currentTabId == 'edit') {
        this.initCardClipboard();
      }
    });
  }

  startHistoryMonitor() {
    window.addEventListener('popstate', (e) => this.popResourceFromHistory(e));
  }

  stopHistoryMonitor() {
    window.removeEventListener('popstate', (e) =>
      this.popResourceFromHistory(e),
    );
  }

  startWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.addEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  stopWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.removeEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  updatePackSessionStorage = () => {
    const currentPackId = this.state.currentPackId;

    SessionStorageHelper.setPackItem(currentPackId, 'currentPack', this.state.currentPack);
    SessionStorageHelper.setPackItem(currentPackId, 'currentPackDecks', this.state.currentPack.decks);
    SessionStorageHelper.setPackItem(currentPackId, 'currentPackMetadata', this.state.currentPack.metadata || null);
    SessionStorageHelper.setPackItem(currentPackId, 'currentPackLearners', this.state.currentPack.learners || null);
    SessionStorageHelper.setPackItem(currentPackId, 'currentDeck', this.state.currentDeck || null);
    SessionStorageHelper.setPackItem(currentPackId, 'currentDeckCards', this.state.currentDeck?.cards || null);
  }

  verifyCurrentTabId = (tabId='preview') => {
    const currentPack = this.state.currentPack;

    if (!currentPack) {
      return 'preview';
    }

    let verifiedTabId = tabId;

    if (VALID_TAB_IDS.indexOf(verifiedTabId) == -1) {
      return 'preview';
    }

    if (verifiedTabId == 'edit' && ['admin', 'edit'].indexOf(currentPack.permission) == -1) {
      return 'preview'
    }

    return verifiedTabId;
  }  
}

DeckDetailController.propTypes = PT;

export default DeckDetailController;
