
import model from '_core/newModel';

const deckCardImport = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId, cardRows) {
    const path = `packs/${packId}/decks/${deckId}/import`;
    
    const deckData = {
      cards: cardRows,
    };

    return model.create(path, deckData);
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckCardImport;
