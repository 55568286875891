
import React, { useState, useEffect }   from 'react';

import { toClassStr }                   from '_utils/UiHelper';


const MAX_RANGE_VAL = 100;
const MIN_RANGE_VAL = 5;
const STEP_COUNT = 5;


const IncrementalCounter = ({ 
  defaultVal = MIN_RANGE_VAL, 
  maxVal = MAX_RANGE_VAL, 
  minVal = MIN_RANGE_VAL, 
  onChange = null, 
  stepCount = STEP_COUNT,
}) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [value, setValue] = useState(defaultVal);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);  

  
  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleIncreaseButtonClick = () => {
    if (value + stepCount <= maxVal) {
      setValue(value + stepCount);

    } else {
      setValue(maxVal);
    }
  }

  const handleDecreaseButtonClick = () => {
    if (value - stepCount >= minVal) {
      setValue(value - stepCount);
    } else {
      setValue(minVal);
    }
  }

  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['incremental-counter']);

  return (
    <div className={classes}>
      <div 
        className='counter-button decrease'
        onClick={handleDecreaseButtonClick} 
        disabled={value >= minVal}>
      </div>

      <span className='counter-value'>{value}</span>

      <div 
        className='counter-button increase'
        onClick={handleIncreaseButtonClick} 
        disabled={value <= maxVal}>
      </div>
    </div>
  );
}

export default IncrementalCounter;
