
// substrate and utils
import EventManager                         from '@brainscape/event-manager';
import PropTypes                            from 'prop-types';
import React                                from 'react';
import {toClassStr}                         from '_utils/UiHelper';
    
// top z-level view controllers
import DetailLoadingOverlayController       from '_controllers/DetailLoadingOverlayController';
import PageLoadingOverlayController         from '_controllers/PageLoadingOverlayController';
import ToastController                      from '_controllers/ToastController';
import TooltipController                    from '_controllers/TooltipController';
    
// sub-components
import DashboardSidebar                     from '_views/shared/DashboardSidebar';
import DashboardMobileTopbar                from '_views/shared/DashboardMobileTopbar';
import EmptyLibraryGuidance                 from '_views/shared/EmptyLibraryGuidance';
import FtueMessaging                        from '_views/shared/ftue/FtueMessaging';
import PackDetailBody                       from '_views/pack-detail/desktop/PackDetailBody';
import PrivateClassOverlay                  from '_views/shared/PrivateClassOverlay';
import TransitionWrapper                    from '_views/shared/TransitionWrapper';

// modal view controllers
import CautionModalController               from '_controllers/modals/CautionModalController';
import CopyAndPlaceDeckModalSetController   from '_controllers/modals/CopyAndPlaceDeckModalSetController';
import CopyAndPlaceCardsModalSetController  from '_controllers/modals/CopyAndPlaceCardsModalSetController';
import CreateDeckModalController            from '_controllers/modals/CreateDeckModalController';
import CreatePackModalController            from '_controllers/modals/CreatePackModalController';
import DeckPreviewModalController           from '_controllers/modals/DeckPreviewModalController';
import EarnMoneyModalController             from '_controllers/modals/EarnMoneyModalController';
import EditDeckInfoModalController          from '_controllers/modals/EditDeckInfoModalController';
import EditPackPrivacyModalController       from '_controllers/modals/EditPackPrivacyModalController';
import GroupLicenseRequiredModalController  from '_controllers/modals/GroupLicenseRequiredModalController';
import ImportDeckModalController            from '_controllers/modals/ImportDeckModalController';
import InfoModalController                  from '_controllers/modals/InfoModalController';
import DialogModalController                from '_controllers/modals/DialogModalController';
import LearnerProgressModalController       from '_controllers/modals/LearnerProgressModalController';
import ManageDeckCategoriesModalController  from '_controllers/modals/ManageDeckCategoriesModalController';
import MessageAuthorModalSetController      from '_controllers/modals/MessageAuthorModalSetController';
import MakeFlashcardsModalController        from '_controllers/modals/ai/MakeFlashcardsModalController';
import RequiredMetadataModalController      from '_controllers/modals/RequiredMetadataModalController';
import SharePackByLinkModalController       from '_controllers/modals/SharePackByLinkModalController';
import SharePackModalSetController          from '_controllers/modals/SharePackModalSetController';
import SuggestEditModalSetController        from '_controllers/modals/SuggestEditModalSetController';
import UpgradeModalController               from '_controllers/modals/UpgradeModalController';

import GetTheAppModalController             from '_controllers/modals/GetTheAppModalController';

// modals
// import ImportQAListModal                    from '_views/modals/ai/import/ImportQAListModal';

// images
import defaultPackIconLightBg               from '_images/shared/ugc-clear-bg.svg';
import defaultPackIconDarkBg                from '_images/shared/ugc-blue-bg.svg';
import defaultAvatar                        from '_images/shared/avatar-generic-square.png';
// import { ParseCardList } from 'parse-card-list';


const PT = {      
  addClasses:                           PropTypes.string,
  currentDeckId:                        PropTypes.node,
  currentPack:                          PropTypes.object,
  currentTabId:                         PropTypes.string,
  currentUser:                          PropTypes.object,
  currentUserPacks:                     PropTypes.array,
  estimatedTimeLeft:                    PropTypes.number,
  isFtue:                               PropTypes.bool,
  isLoadingPack:                        PropTypes.bool,
  isLoadingPackDecks:                   PropTypes.bool,
  isLoadingPackLearners:                PropTypes.bool,
  isLoadingPackMetadata:                PropTypes.bool,
  isLoadingTrendingPacks:               PropTypes.bool,
  isLoadingUser:                        PropTypes.bool,
  isLoadingUserPacks:                   PropTypes.bool,
  isLoadingTrendingPacks:               PropTypes.bool,
  isMobileViewportSize:                 PropTypes.bool,
  isMobileSidebarOpen:                  PropTypes.bool,
  isRestrictedByPrivacy:                PropTypes.bool,
  isShowingCachedPackLearners:          PropTypes.bool,
  isShowingCachedUserPacks:             PropTypes.bool,
  sidebarMode:                          PropTypes.string,
  totalTimeStudied:                     PropTypes.number,
  trendingPacks:                        PropTypes.array,
};


class PackDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldPulseAddCardsButton: false,
      shouldPulseCreateDeckButton: false,
      shouldPulseCreatePackButton: false,
      shouldPulseFindButton: false,
      shouldPulseStudyButton: false,
    };

    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.startBrokenImageMonitor();
    this.showCreatePackModal();
    this.managePackLoadingOverlay();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoadingPack != this.props.isLoadingPack) {
      this.managePackLoadingOverlay();
    }

    if (prevProps.isLoadingPackDecks != this.props.isLoadingPackDecks) {
      this.managePackLoadingOverlay();
    }
  }

  componentWillUnmount() {
    this.stopBrokenImageMonitor();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const isMiniSidebarClass = (this.props.sidebarMode == 'mini' ? 'is-mini-sidebar' : '');
    const classes = toClassStr(['desktop-page pack-detail-page', isMiniSidebarClass, this.props.addClasses]);

    return (
      <div className={classes}>
        <TooltipController />
        <ToastController />
        <PageLoadingOverlayController />
        <DetailLoadingOverlayController />

        <div className="page-shell">
          {this.renderDashboardMobileTopbar()}
          {this.renderDashboardMobileSidebar()}
          {this.renderDashboardDesktopSidebar()}
          {this.renderPackDetailBody()}
          {this.renderPrivateClassOverlay()}
          {this.renderEmptyLibraryMessaging()}
          {this.renderUnownedPackMessaging()}
          {this.renderFtueMessaging()}
        </div>

        <CautionModalController />
        <CreateDeckModalController />
        <CreatePackModalController />
        <CopyAndPlaceCardsModalSetController />
        <CopyAndPlaceDeckModalSetController currentUserPacks={this.props.currentUserPacks} />
        <DeckPreviewModalController />
        <EarnMoneyModalController />
        <EditDeckInfoModalController />
        <EditPackPrivacyModalController />
        <GroupLicenseRequiredModalController />
        <ImportDeckModalController />
        <InfoModalController />
        <DialogModalController />
        <LearnerProgressModalController />
        <MakeFlashcardsModalController 
          currentPackId={this.props.currentPackId}
          currentUser={this.props.currentUser}
        />
        <ManageDeckCategoriesModalController />
        <MessageAuthorModalSetController />
        <RequiredMetadataModalController isMobileViewportSize={this.props.isMobileViewportSize} />
        <SharePackModalSetController isMobileViewportSize={this.props.isMobileViewportSize} />
        <SharePackByLinkModalController />
        <SuggestEditModalSetController />
        <UpgradeModalController />
        <GetTheAppModalController />

        {/* <ImportQAListModal deckId={this.props.currentDeckId} packId={this.props.currentPackId}/> */}
      </div>
    );
  }

  renderDashboardDesktopSidebar = () => {
    if (this.props.isMobileViewportSize) {
      return null;
    }

    return this.renderDashboardSidebar();
  }

  renderDashboardMobileTopbar() {
    if (!this.props.isMobileViewportSize) {
      return null;
    }

    return (
      <DashboardMobileTopbar
        isLoadingUserInfo={this.props.isLoadingUser}
        currentUser={this.props.currentUser}
        onShowClassesRequest={() => this.handleShowClassesRequest()}
        userPacks={this.props.currentUserPacks}
      />
    );
  }

  renderDashboardMobileSidebar() {
    // NOTE: This is not really a "Sidebar", it's an overlay
    if (!this.props.isMobileViewportSize) {
      return null;
    }

    const isOpen = this.props.isMobileViewportSize && this.props.isMobileSidebarOpen == true;

    return (
      <TransitionWrapper
        addClasses="dashboard-mobile-sidebar-transition-wrapper"
        onTransitionedOut={() => {}}
        shouldTransitionIn={isOpen}
        shouldTransitionOut={!isOpen}
        transitionInDelay={100}
        transitionInDuration={500}
        transitionOutDuration={500}
      >
        {this.renderDashboardSidebar()}
      </TransitionWrapper>
    );
  }

  renderDashboardSidebar = () => {
    const isEmptyLibrary = !(this.props.currentUserPacks.length > 0);
    const currentUser = this.props.currentUser;

    return (
      <DashboardSidebar
        currentDeckId={this.props.currentDeckId}
        currentPack={this.props.currentPack}
        currentPackId={this.props.currentPack.packId}
        currentUser={this.props.currentUser}
        isEmptyLibrary={isEmptyLibrary}
        isLoadingTrendingPacks={this.props.isLoadingTrendingPacks}
        isLoadingUserInfo={this.props.isLoadingUser}
        isLoadingUserPacks={this.props.isLoadingUserPacks}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isPackDetailView={true}
        isRestrictedByPrivacy={this.props.isRestrictedByPrivacy}
        isShowingCachedUserPacks={this.props.isShowingCachedUserPacks}
        onHideClassesRequest={() => this.handleHideClassesRequest()}
        shouldPulseCreatePackButton={this.state.shouldPulseCreatePackButton}
        shouldPulseFindButton={this.state.shouldPulseFindButton}
        sidebarMode={this.props.sidebarMode}
        trendingPacks={this.props.trendingPacks}
        userPacks={this.props.currentUserPacks}
      />
    )
  }

  renderPackDetailBody = () => {
    if (this.props.isRestrictedByPrivacy) {
      return null;
    }

     if (this.props.isLoadingPack || this.props.isLoadingPackDecks) {
       return null;
     }

    if (!this.props.currentPackId) {
      return (
        <EmptyLibraryGuidance />
      );
    }

    return (
      <PackDetailBody
        currentDeckId={this.props.currentDeckId}
        currentPack={this.props.currentPack}
        currentTabId={this.props.currentTabId}
        currentUser={this.props.currentUser}
        estimatedTimeLeft={this.props.estimatedTimeLeft}
        isFtse={this.props.currentUser.flags.isFtse}
        isFtue={this.props.isFtue}
        isLoadingPackDetail={this.props.isLoadingPack || this.props.isLoadingPackDecks}
        isLoadingPack={this.props.isLoadingPack}
        isLoadingPackDecks={this.props.isLoadingPackDecks}
        isLoadingPackLeaderboard={this.props.isLoadingPackLeaderboard}
        isLoadingPackLearners={this.props.isLoadingPackLearners}
        isLoadingPackMetadata={this.props.isLoadingPackMetadata}
        isLoadingUserInfo={this.props.isLoadingUser}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isShowingCachedPackLearners={this.props.isShowingCachedPackLearners}
        shouldPulseAddCardsButton={this.state.shouldPulseAddCardsButton}
        shouldPulseCreateDeckButton={this.state.shouldPulseCreateDeckButton}
        shouldPulseStudyButton={this.state.shouldPulseStudyButton}
        totalTimeStudied={this.props.totalTimeStudied}
      />
    );
  }

  renderPrivateClassOverlay() {
    if (!this.props.isRestrictedByPrivacy) {
      return null;
    }

    return (
      <PrivateClassOverlay 
        currentPack={this.props.currentPack}
        currentUser={this.props.currentUser}
      />
    );
  }

  renderEmptyLibraryMessaging = () => {
    return null;
  }

  renderUnownedPackMessaging = () => {
    return null;
  }

  renderFtueMessaging = () => {
    if (!this.props.isFtue) {
      return null;
    }

    const currentView = (this.props.isMobileViewportSize && this.props.isMobileSidebarOpen) ? 'mobile-class-index' : 'pack-detail';

    return (
      <FtueMessaging
        currentPack={this.props.currentPack}
        currentUser={this.props.currentUser}
        currentView={currentView}
        isFtue={this.props.isFtue}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  };


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBrokenImageError = (e) => {
    e.stopPropagation();

    const elem = e.target;
    const imageKind = elem.getAttribute('data-img-kind');

    if (!imageKind) {
      return false;
    }

    let src;

    switch (imageKind) {
      case 'avatar':
        src = defaultAvatar;
      break;

      case 'ugsIconWhiteBg': 
        src = defaultPackIconLightBg;
      break;

      case 'ugsIconBlueBg': 
        src = defaultPackIconDarkBg;
      break;

      case 'catIcon':
        src = defaultPackIconLightBg;
      break;
    }

    if (!src) {
      return false;
    }

    elem.src = src;
  }

  handleShowClassesRequest = () => {
    this.props.handleShowClassesRequest();
  }

  handleHideClassesRequest = () => {
    this.props.handleHideClassesRequest();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCreatePackModalOpen = () => {
    EventManager.emitEvent('create-pack-modal:open', {});
  }

  triggerDetailLoadingOverlayClose = () => {
    EventManager.emitEvent('detail-loading-overlay:close', {});
  }

  triggerDetailLoadingOverlayOpen = (message) => {
    EventManager.emitEvent('detail-loading-overlay:open', {message: message});
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  showCreatePackModal = () => {
    if (this.props.currentUser.uiTriggers.modals.createPack) {
      this.triggerCreatePackModalOpen();
    }
  };

  startBrokenImageMonitor = () => {
    document.addEventListener('error', (e) => {
      if (e.target.tagName.toLowerCase() !== 'img') {
        return false;
      }

      this.handleBrokenImageError(e);
    }, true);  
  }

  stopBrokenImageMonitor = () => {
    document.removeEventListener('error', (e) => {
      if (e.target.tagName.toLowerCase() !== 'img') {
        return false;
      }

      this.handleBrokenImageError(e);
    }, true);  
  }

  managePackLoadingOverlay = () => {
    if (this.props.isLoadingPack) {
      this.triggerDetailLoadingOverlayOpen('Loading Class Detail...');
      return true;
    } 

    if (this.props.isLoadingPackDecks) {
      this.triggerDetailLoadingOverlayOpen('Loading Class Detail...');
      return true;
    } 

    this.triggerDetailLoadingOverlayClose();
  }
}

PackDetailPage.propTypes = PT;

export default PackDetailPage;
