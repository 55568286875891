
import AiExceptionPanel                 from '_views/modals/ai/AiExceptionPanel';
import BruceWaitPanel                   from '_views/modals/ai/BruceWaitPanel';
import EventManager                     from '@brainscape/event-manager';
import GenerateFromTopicPanel           from '_views/modals/ai/GenerateFromTopicPanel';
import ImportPasteFlashcardsPanel       from '_views/modals/ai/ImportPasteFlashcardsPanel';
import Modal                            from '_views/shared/Modal';
import PasteTextPanel                   from '_views/modals/ai/PasteTextPanel';
import React                            from 'react';
import ReviewCardRowsPanel              from '_views/modals/ai/ReviewCardRowsPanel';
import SelectMethodPanel                from '_views/modals/ai/SelectMethodPanel';
import SummarizeFromContentPanel        from '_views/modals/ai/SummarizeFromContentPanel';
import UploadFilePanel                  from '_views/modals/ai/UploadFilePanel';

import { toClassStr }                   from '_utils/UiHelper';


const MakeFlashcardsModal = ({ 
  acceptedFileTypes,
  cardRows, 
  createMethod, 
  currentPanel, 
  currentUser,
  errorMessage, 
  hasInvalidInput, 
  initialInputText,
  initialInputTopicData,
  isAiListParser, 
  isAiParsable, 
  isAiProcessComplete,
  isBackable,
  isDirectToCards=false,
  isProcessing, 
}) => {
  
  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleBackButtonClick = () => {
    triggerGoBackRequest();
  };

  const handleFileDragEnter = () => {
    triggerUploadFileRequest();
  }

  const handleModalClose = () => {
    triggerModalCloseRequest();
  };

  const handleModalClosed = () => {
    console.log('Modal closed');
  };

  const handlePasteTextButtonClick = () => {
    triggerPasteTextRequest();
  };

  const handleUploadFileButtonClick = () => {
    triggerUploadFileRequest();
  };

  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerModalCloseRequest = () => {  
    EventManager.emitEvent('make-flashcards-modal:close', {});
  }

  const triggerGoBackRequest = () => {
    EventManager.emitEvent('make-flashcards-modal:back-request', {});
  }

  const triggerPasteTextRequest = () => {
    EventManager.emitEvent('import-list-panel:paste-text-request', {});
  }

  const triggerUploadFileRequest = () => {
    EventManager.emitEvent('import-list-panel:upload-file-request', {});
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderModalContent = () => {
    switch (currentPanel) {
      case 'freeAiException':
        return (
          <AiExceptionPanel 
            exceptionType={'freeAiException'}
          />
        );
      case 'generalAiException':
        return (
          <AiExceptionPanel
            errorMessage={errorMessage}
            exceptionType={'generalAiException'}
          />
        ); 
      case 'selectMethod':
        return (
          <SelectMethodPanel 
            isDirectToCards={isDirectToCards}
          />
        );
      case 'loading':
        return (
          <BruceWaitPanel 
            isAiProcessComplete={isAiProcessComplete}
          />
        );
      case 'pasteText':
        return (
          <PasteTextPanel
            createMethod={createMethod}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            initialInputText={initialInputText}
            isProcessing={isProcessing}
          />
        );
      case 'proAiException':
        return (
          <AiExceptionPanel 
            exceptionType={'proAiException'}
          />
        );
      case 'review':
        return (
          <ReviewCardRowsPanel 
            cardRows={cardRows}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isAiListParser={isAiListParser}
            isAiParsable={isAiParsable}
            isProcessing={isProcessing}
          />
        );
      case 'aiReview':
        return (
          <ReviewCardRowsPanel 
            cardRows={cardRows}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isAiListParser={isAiListParser}
            isAiParsable={isAiParsable}
            isProcessing={isProcessing}
          />
        );
      case 'generateFromTopic':
        return (
          <GenerateFromTopicPanel
            initialInputTopicData={initialInputTopicData}
          />
        );
      case 'summarizeFromContent':
        return (
          <SummarizeFromContentPanel
            acceptedFileTypes={acceptedFileTypes}
            currentUser={currentUser}
          />
        );
      case 'uploadFile':
        return (
          <UploadFilePanel
            acceptedFileTypes={acceptedFileTypes}
            createMethod={createMethod}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isProcessing={isProcessing}
          />
        );
      case 'importPasteFlashcards':
        return (
          <ImportPasteFlashcardsPanel
            acceptedFileTypes={acceptedFileTypes}
          />
        );
      default:
        return null;
    }
  };


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const isDroppable = (currentPanel == 'importPasteFlashcards');
  const isSelectMethodPanel = (currentPanel == 'selectMethod' ? 'select-method-panel' : '');
  const mediumModalClass = (['review', 'aiReview'].includes()) ? 'is-medium-modal' : 'is-medium-modal';
  const classes = toClassStr(['make-flashcards-modal', isSelectMethodPanel, mediumModalClass]);

  return (
    <Modal 
      addClasses={classes}
      isDroppable={isDroppable}
      isOpen={true} 
      onClosed={handleModalClosed}
      onCloseRequest={handleModalClose}
      onFileDragEnter={handleFileDragEnter}
      onBackIconButtonClick={handleBackButtonClick}
      showBackButton={isBackable}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default MakeFlashcardsModal;
