
import React from 'react';

const ButtonInfoWrapper = ({ children, additionalText }) => {

  return (
    <div className='button-info-wrapper'>
      {children}

      <div className='button-info'>
        {additionalText}
      </div>
    </div>
  );
}

export default ButtonInfoWrapper;
