
import { trackEvent }                           from '_services/AnalyticsService';
import AiListParserService                      from '_services/AiListParserService';
import AiTopicGenerator                         from '_services/AiTopicGenerator';
import deckCardImport                           from '_models/deckCardImport';
import EventManager                             from '@brainscape/event-manager';
import MakeFlashcardsModal                      from '_views/modals/ai/MakeFlashcardsModal';
import packDeckCardImport                             from '_models/packDeckCardImport';
import ParseCardListService                     from '_services/ParseCardListService';
import React, { useState, useEffect, useRef }   from 'react';


const ACCEPTED_FILE_TYPES = {
  importPasteFlashcards: ['.csv', '.txt', '.xlsx', '.ods'], 
  summarizeFromContent: ['.docx', '.pptx', '.txt'],
};
const AI_PARSABLE_FILE_TYPES = ['.csv', '.txt'];

// TODO: Checking for the following error messages is a temporary solution. We should have a more robust way to check for these errors.
const AI_TOKEN_EXCEPTION_FREE_USER_MSG = 'You have exceeded your free AI token quota.';
const AI_TOKEN_EXCEPTION_PRO_USER_MSG = 'You have exceeded your daily AI token quota.';

const PROGRESS_BAR_COMPLETION_DELAY = 3500;

const BACKABLE_PANELS = ['importPasteFlashcards', 'summarizeFromContent', 'generateFromTopic', 'pasteText', 'uploadFile', 'review', 'aiReview'];
const HISTORY_PANELS = ['selectMethod', 'importPasteFlashcards', 'summarizeFromContent', 'generateFromTopic', 'pasteText', 'uploadFile', 'review', 'aiReview'];

const CREATE_METHOD_LABELS = {
  importPasteFlashcards: 'importer',
  summarizeFromContent: 'summarize',
  generateFromTopic: 'tell-ai',
};

let progressBarTimeout = null;


const MakeFlashcardsModalController = ({
  currentPackId,
  currentUser,
}) => {  
  /*
  ==================================================
   INIT 
  ==================================================
  */

  const eventManager = new EventManager();


  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [currentPanel, setCurrentPanel] = useState(''); // selectMethod, importPasteFlashcards, summarizeFromContent, generateFromTopic, pasteText, uploadFile, url, review, aiReview, loading, freeAiException, proAiException, generalAiException
  const [hasInvalidInput, setHasInvalidInput] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const cardRows = useRef([]);
  const createMethod = useRef(null);
  const currentPackIdRef = useRef(currentPackId);
  const currentPanelRef = useRef(currentPanel);
  const currentDeckId = useRef(null);
  const errorMessage = useRef('');
  const fileExtension = useRef('');
  const fileName = useRef('');
  const initialPanel = useRef(null);
  const inputArrayBuffer = useRef(null);
  const inputMethod = useRef(null);
  const inputText = useRef('');
  const inputTopicData = useRef({});
  const inputUrl = useRef('');
  const isAiListParser = useRef(false);
  const isAiProcessComplete = useRef(false);
  const isDirectToCards = useRef(false);
  const preAiParseCardRowCount = useRef(0);
  const panelHistory = useRef([]);

  
  // on Mount
  useEffect(() => {
    clearTimeout(progressBarTimeout);

    /* SUBSCRIBE TO EVENTS */
    eventManager.addListener('make-flashcards-modal:open', handleOpenModal);
    eventManager.addListener('make-flashcards-modal:close', handleCloseModal);
    eventManager.addListener('make-flashcards-modal:type-flashcards-request', handleTypeFlashcardsRequest);
    eventManager.addListener('make-flashcards-modal:import-paste-flashcards-request', handleImportPasteFlashcardsRequest);
    eventManager.addListener('make-flashcards-modal:summarize-from-content-request', handleSummarizeFromContentRequest);
    eventManager.addListener('make-flashcards-modal:generate-from-topic-request', handleGenerateFromTopicRequest);
    eventManager.addListener('make-flashcards-modal:back-request', handleBackRequest);

    eventManager.addListener('import-paste-flashcards-panel:paste-text-request', handlePasteTextRequest);
    eventManager.addListener('import-paste-flashcards-panel:upload-file-request', handleUploadFileRequest);
    
    eventManager.addListener('paste-text-panel:input-text-submit', handleInputTextSubmit);
    eventManager.addListener('paste-text-panel:back-request', handleBackRequest);

    eventManager.addListener('upload-file-panel:input-file-submit', handleInputFileSubmit);
    eventManager.addListener('upload-file-panel:back-request', handleBackRequest);
    eventManager.addListener('upload-file-panel:file-selected', handleFileSelected);
    eventManager.addListener('upload-file-panel:file-selection-error', handleFileSelectionError);

    eventManager.addListener('review-card-rows-panel:add-cards-request', handleAddCardsRequest);
    eventManager.addListener('review-card-rows-panel:fix-with-ai-request', handleFixWithAiRequest);
    eventManager.addListener('review-card-rows-panel:cancel-import-request', handleCancelImportRequest);

    eventManager.addListener('generate-from-topic-panel:input-topic-submit', handleInputTopicSubmit); 
    eventManager.addListener('generate-from-topic-panel:cancel-topic-request', handleCancelTopicRequest); 


    // on Unmount
    return () => {
      eventManager.disable();
      clearTimeout(progressBarTimeout);
    };
  }, []);

  useEffect(() => {
    currentPanelRef.current = currentPanel;
  }, [currentPanel]);  

  useEffect(() => {
    currentPackIdRef.current = currentPackId;
  }, [currentPackId]);  

  useEffect(() => {
    if (isModalOpen) {
      trackEvent(getCreateMethodLabel(), `view/${currentPanel}`);
    }
  }, [currentPanel, isModalOpen]);
    
  useEffect(() => {
    if (currentPanel !== 'generalAiException') {
      errorMessage.current = '';
    }
  }, [currentPanel]);


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleAcceptedDataParseError = (err) => {
    console.error(err);
    errorMessage.current = `Could not process the input data: ${err.message}`;
    setHasInvalidInput(true);
    setIsProcessing(false);

    trackEvent(getCreateMethodLabel(), 'choose_file', {error: true});    
  }

  const handleAddCardsRequest = (eventData) => {
    if (currentDeckId.current) {
      addCardsToDeck(eventData.cardRows);
    } else {
      addCardsAndDeckToPack(eventData.cardRows);
    }
  }

  const handleAiListParseCompleted = () => {
    clearTimeout(progressBarTimeout);
    isAiProcessComplete.current = true;

    // let the loading panel animate to 100% before showing the review panel
    progressBarTimeout = setTimeout(() => {
      navigateToNextPanel('aiReview');
      isAiProcessComplete.current = false;

      clearTimeout(progressBarTimeout);
    }, PROGRESS_BAR_COMPLETION_DELAY);
  }

  const handleAiListParseError = (err) => {
    const errMessage = err.message;

    if (errMessage.indexOf(AI_TOKEN_EXCEPTION_FREE_USER_MSG) != -1) {
      navigateToNextPanel('freeAiException');
      trackEvent(getCreateMethodLabel(), 'ai/quota_exceeded', {pro: false});
      return true;
    }

    if (errMessage.indexOf(AI_TOKEN_EXCEPTION_PRO_USER_MSG) != -1) {
      navigateToNextPanel('proAiException');
      trackEvent(getCreateMethodLabel(), 'ai/quota_exceeded', {pro: true});
      return true;
    }

    errorMessage.current = errMessage;
    
    navigateToNextPanel('generalAiException');
    trackEvent(getCreateMethodLabel(), 'ai/general_ai_exception', {
      err: errorMessage.current,
    });
  }

  const handleBackRequest = () => {
    const prevPanel = tryPreviousPanel();

    if (!prevPanel) {
      console.log('Bad Back Request');
    }
  }

  const handleCancelImportRequest = () => {
    trackEvent(getCreateMethodLabel(), 'cancel/exit');
    clearHistory();
    setIsModalOpen(false);
  }

  const handleCancelTopicRequest = () => {
    trackEvent(getCreateMethodLabel(), 'cancel/exit');
    clearHistory();
    setIsModalOpen(false);
  }

  const handleCardsGenerated = () => {
    setIsModalOpen(false);
    clearHistory();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    trackEvent(getCreateMethodLabel(), 'cancel/exit');
    clearHistory();
  };

  const handleFileSelected = () => {
    setHasInvalidInput(false);
    errorMessage.current = '';
  }

  const handleFileSelectionError = (eventData) => {
    setHasInvalidInput(true);
    errorMessage.current = eventData.errorMessage;
  }

  const handleFixWithAiRequest = () => {
    takePanelSnapshot();

    ParseCardListService.setParser(AiListParserService);
    isAiListParser.current = true;
    navigateToNextPanel('loading');
    parseInputData({arrayBuffer: inputArrayBuffer.current, parserName: 'AiListParser'});
  }

  const handleGenerateFromTopicRequest = () => {
    takePanelSnapshot();
    createMethod.current = 'generateFromTopic';
    navigateToNextPanel('generateFromTopic');
  }

  const handleInputFileSubmit = (eventData) => {
    takePanelSnapshot();

    setIsProcessing(true);
    isAiListParser.current = false;
    fileExtension.current = ''; // reset file extension
    fileName.current = ''; // reset file name

    ParseCardListService.acceptFile(eventData.inputFile).then((inputData) => {
      trackEvent(getCreateMethodLabel(), 'choose_file', {
        name: inputData.fileName,
        size: inputData.arrayBuffer.byteLength,
      });

      parseInputData(inputData);
    }).catch((err) => {
      trackEvent(getCreateMethodLabel(), 'choose_file', { error: true });
      handleAcceptedDataParseError(err);
    });
  };

  const handleImportPasteFlashcardsRequest = () => {
    takePanelSnapshot();
    createMethod.current = 'importPasteFlashcards';
    navigateToNextPanel('importPasteFlashcards');
  }

  const handleInputTextSubmit = (eventData) => {
    setIsProcessing(true);
    inputText.current = eventData.inputText;
    takePanelSnapshot();

    ParseCardListService.acceptText(eventData.inputText).then((inputData) => {
      parseInputData(inputData);
    }).catch((err) => {
      handleAcceptedDataParseError(err);
    });
  };

  const handleInputTopicSubmit = (eventData) => {
    inputTopicData.current = eventData;
    takePanelSnapshot();

    navigateToNextPanel('loading');
    generateCardsFromTopic(eventData);
  }

  const handleOpenModal = (eventData) => {  // keys: panel, deckId, packId, isDirectToCards
    clearHistory();
    resetInputData();

    navigateToNextPanel(eventData.panel || 'selectMethod');
    createMethod.current = eventData.panel || null;
    initialPanel.current = eventData.panel || 'selectMethod';
    isDirectToCards.current = eventData.isDirectToCards || false;   
    currentDeckId.current = eventData.deckId || null;

    setIsProcessing(false);
    setHasInvalidInput(false);
    setIsModalOpen(true);
  };

  const handleParseInputDataError = (err) => {
    if (isAiListParser.current) {
      handleAiListParseError(err);
    }

    errorMessage.current = `Could not parse the input data: ${err.message}.`;
    setIsProcessing(false);
    setHasInvalidInput(true);
  }

  const handlePasteTextRequest = () => {
    takePanelSnapshot();

    inputMethod.current = 'text';
    navigateToNextPanel('pasteText');
  }

  const handleSummarizeFromContentRequest = () => {
    takePanelSnapshot();

    createMethod.current = 'summarizeFromContent';
    navigateToNextPanel('summarizeFromContent');
  }

  const handleTypeFlashcardsRequest = () => {
    setIsModalOpen(false);
    EventManager.emitEvent('deck-detail-view:change-request', {
      deckId: currentDeckId.current,
      packId: currentPackIdRef.current,
      tabId: 'edit',
    });
  }

  const handleUploadFileRequest = () => {
    takePanelSnapshot();
    inputMethod.current = 'file';
    navigateToNextPanel('uploadFile');
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  const publishDeckCardsGenerated = (packId, deckId, cardCount) => {
    EventManager.emitEvent('deck-cards:generated', {
      deckId: deckId,
      packId: packId,
      newCardCount: cardCount,
    })
  }
  
  const publishPackDeckCardsGenerated = (packId, deckId, cardCount) => {
    EventManager.emitEvent('pack-deck-cards:generated', {
      deckId: deckId,
      packId: packId,
      newCardCount: cardCount,
    })
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const addCardsAndDeckToPack = (newCardRows) => {
    const deckName = getDeckName();
    const shouldSuppressModelPublish = true;
    setIsProcessing(true);

    packDeckCardImport.create(currentPackIdRef.current, newCardRows, deckName, shouldSuppressModelPublish)
      .then((rs) => {
        trackEvent(getCreateMethodLabel(), 'add', {
          ct: newCardRows.length, // ct = cardCount
          packId: currentPackIdRef.current,
          deckId: rs.deckId,
        }); 

        publishPackDeckCardsGenerated(currentPackIdRef.current, rs.deckId, newCardRows.length);
        handleCardsGenerated();
      })
      .catch(err => {
        console.error(err);
      });
  }

  const addCardsToDeck = (newCardRows) => {
    const shouldSuppressModelPublish = true;
    setIsProcessing(true);

    deckCardImport.create(currentPackIdRef.current, currentDeckId.current, newCardRows, shouldSuppressModelPublish)
      .then((rs) => {
        trackEvent(getCreateMethodLabel(), 'add', {
          ct: newCardRows.length, // ct = cardCount
          packId: currentPackIdRef.current,
          deckId: currentDeckId.current,
        }); 

        publishDeckCardsGenerated(currentPackIdRef.current, currentDeckId.current, newCardRows.length);
        handleCardsGenerated();
      })
      .catch(err => {
        console.error(err);
      });
  }

  const clearHistory = () => {
    panelHistory.current = [];
  }

  const generateCardsFromTopic = (topicData) => {
    const reqData = {
      card_ct: topicData.cardCount,
      a_lang: topicData.answerLanguage,
      q_lang: topicData.questionLanguage,
      topic: topicData.topic,
    };

    AiTopicGenerator.generate(reqData)
      .then((generatedCardRows) => {
        cardRows.current = generatedCardRows
        navigateToNextPanel('aiReview');
      })
      .catch((err) => {
        console.error(err);
        handleAiListParseError(err); // TODO: Change the name of this and other structures to be general to all AI generation types
      });
  }

  const getCreateMethodLabel = () => {
    return CREATE_METHOD_LABELS[createMethod.current];
  }

  const getDeckName = () => {
    if (inputMethod.current == 'file') {
      const newFileName = ParseCardListService.getFileName();

      if (!newFileName) {
        return 'Untitled Deck';
      }

      return newFileName.split('.').slice(0, -1).join('.');
    }

    return 'Untitled Deck';
  }

  const getFileExtension = (_fileName) => {
    if (!_fileName) { return null; }

    return `.${_fileName.split('.').pop()}`;
  }

  const isAiParsable = () => {
    if (createMethod.current != 'importPasteFlashcards') {
      return false;
    }

    if (inputMethod.current === 'text') {
      return true;
    }

    if (fileExtension.current && AI_PARSABLE_FILE_TYPES.includes(fileExtension.current)) {
      return true;
    }

    return false;
  }

  const isCurrentPanelBackable = () => {
    return panelHistory.current.at(-1).isBackable;
  }

  const navigateToNextPanel = (nextPanelName) => {
    if (HISTORY_PANELS.includes(nextPanelName)) {
      pushPanelState(nextPanelName);
    }

    setCurrentPanel(nextPanelName);
  }

  const parseInputData = (inputData) => {
    inputArrayBuffer.current = inputData.arrayBuffer;
    fileName.current = inputData.fileName;
    fileExtension.current = getFileExtension(inputData.fileName);

    if (inputData.parserName === 'AiListParser') {
      trackEvent(getCreateMethodLabel(), 'fix_with_ai');
    } else {
      trackEvent(getCreateMethodLabel(), 'analyze', {name: inputData.fileName});
    }

    ParseCardListService.parseArrayBuffer()
      .then(parsedCardRows => {
        setHasInvalidInput(false); 
        setIsProcessing(false);

        if (inputData.parserName === 'AiListParser') {
          cardRows.current = parsedCardRows;
          handleAiListParseCompleted();
          return true;
        }

        preAiParseCardRowCount.current = parsedCardRows.length;
        cardRows.current = parsedCardRows;
        navigateToNextPanel('review');
      })
      .catch(err => {
        handleParseInputDataError(err);
      });
  }

  const popPanelState = () => {
    if (panelHistory.current.length == 0) {
      return null;
    }

    if (!panelHistory.current.at(-1)?.isBackable) {
      return null;
    }

    panelHistory.current.pop();

    return panelHistory.current[panelHistory.current.length - 1];
  }

  const pushPanelState = (panelName=null) => {
    if (!panelName) {
      return false;
    }

    panelHistory.current.push({
      isBackable: (BACKABLE_PANELS.includes(panelName) && panelHistory.current.length > 0),
      name: panelName,
    });

    return true;
  }

  const resetInputData = () => {
    cardRows.current = [];
    fileName.current = '';
    fileExtension.current = '';
    inputArrayBuffer.current = null;
    inputMethod.current = '';
    inputUrl.current = '';
    inputText.current = '';
    inputTopicData.current = {};
    isAiListParser.current = false;
  }

  const tryPreviousPanel = () => {
    if (!isCurrentPanelBackable()) {
      return false;
    }

    const prevPanelState = popPanelState();

    if (prevPanelState) {
      cardRows.current = prevPanelState.cardRows;
      createMethod.current = prevPanelState.createMethod;
      fileExtension.current = prevPanelState.fileExtension;
      fileName.current = prevPanelState.fileName;
      inputText.current = prevPanelState.inputText;
      inputTopicData.current = prevPanelState.inputTopicData;
      inputUrl.current = prevPanelState.inputUrl;
      isAiListParser.current = prevPanelState.isAiListParser;

      setCurrentPanel(prevPanelState.name);
      return true;
    }

    return false;
  }

  const takePanelSnapshot = () => {
    const currentPanelState = panelHistory.current.at(-1);

    currentPanelState.cardRows = cardRows.current;
    currentPanelState.createMethod = createMethod.current;
    currentPanelState.fileExtension = fileExtension.current;
    currentPanelState.fileName = fileName.current;
    currentPanelState.inputText = inputText.current;
    currentPanelState.inputTopicData = inputTopicData.current;
    currentPanelState.inputUrl = inputUrl.current;
    currentPanelState.isAiListParser = isAiListParser.current;
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  
  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  if (isModalOpen) {
    return (
      <MakeFlashcardsModal
        acceptedFileTypes={ACCEPTED_FILE_TYPES[createMethod.current]}
        cardRows={cardRows.current}
        createMethod={createMethod.current}
        currentPanel={currentPanel}
        currentUser={currentUser}
        errorMessage={errorMessage.current}
        hasInvalidInput={hasInvalidInput}
        initialInputText={inputText.current}
        initialInputTopicData={inputTopicData.current}
        isAiListParser={isAiListParser.current}
        isAiParsable={isAiParsable()}
        isBackable={BACKABLE_PANELS.includes(currentPanel) && panelHistory.current.length > 1}
        isDirectToCards={isDirectToCards.current}
        isProcessing={isProcessing}
        isAiProcessComplete={isAiProcessComplete.current}
      />
    );
  }

  return null;
}

export default MakeFlashcardsModalController;
