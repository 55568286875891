
import {apiDelete} from '_core/Api1';
import Link        from '_views/shared/Link';
import Modal       from '_views/shared/Modal';
import PropTypes   from 'prop-types';
import React       from 'react';

const PT = {
  isOpen              : PropTypes.bool,
  onCloseRequest      : PropTypes.func,
  onIntentSelected    : PropTypes.func,
};

class FtuePromptModal extends React.Component {
  render = () => {
    return (
      <Modal
        addClasses='ftue-prompt-modal'
        isOpen={true}
        onCloseRequest={this.props.onCloseRequest}
        shouldIgnoreOverlayClick={true}
      >
        <div className='modal-title'>How would you like to start?</div>

        <div className='row'>
          <Link onClick={() => this.props.onIntentSelected('find')}>
            <div className='ftue-icon'>
              <i className='ion-ios-search-strong' />
            </div>
            <div className='label'>Find Flashcards</div>
          </Link>

          <Link onClick={() => this.props.onIntentSelected('create')}>
            <div className='ftue-icon'>
              <i className='ion-ios-plus-empty' />
            </div>
            <div className='label'>Make Flashcards</div>
          </Link>

          <Link onClick={() => this.props.onIntentSelected('study')}>
            <div className='ftue-icon'>
              <img src='/assets/knowledge_rehab.svg' />
            </div>
            <div className='label'>Just Get Smarter!</div>
          </Link>
        </div>
      </Modal>
    );
  }

  componentDidMount = () => {
    // We only want to show this dialog once...
    apiDelete('users/me/flags/ftue_prompt').catch((err) => console.log(err));
  }
}

FtuePromptModal.propTypes = PT;

export default FtuePromptModal;
