
import DynamicTooltipIcon               from '_views/shared/DynamicTooltipIcon';
import EventManager                     from '@brainscape/event-manager';
import PillButton                       from '_views/shared/PillButton';
import React                            from 'react';
import SimpleTextButton                 from '_views/shared/SimpleTextButton';

import { toClassStr }                   from '_utils/UiHelper';

const HELP_ARTICLE_URL = 'https://brainscape.zendesk.com/hc/en-us/articles/115002369931-How-do-I-import-a-various-files-correctly-to-create-flashcards-csv-text-xlsx-docx-photos-lessons';
const FORMATTING_HELP_ARTICLE_URL = 'https://brainscape.zendesk.com/hc/en-us/articles/8961531870221-Brainscape-flashcard-formatting-tips-in-the-new-editor';
const IMPORT_HELP_ARTICLE_URL = 'https://brainscape.zendesk.com/hc/en-us/articles/115002369931-How-do-I-import-a-various-files-correctly-to-create-flashcards-csv-text-xlsx-docx-photos-lessons';


const ReviewCardRowsPanel = ({ cardRows, errorMessage, hasInvalidInput, isAiListParser, isAiParsable, isProcessing }) => {

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleAddCardsButtonClick = () => {
    triggerAddCardsRequest();
  }

  const handleFixWithAiButtonClick = () => {
    triggerFixWithAiRequest();
  }

  const handleCancelButtonClick = () => {
    triggerCancelRequest();
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerAddCardsRequest = () => {
    EventManager.emitEvent('review-card-rows-panel:add-cards-request', {
      cardRows: cardRows,
    });
  }

  const triggerCancelRequest = () => {
    EventManager.emitEvent('review-card-rows-panel:cancel-import-request', {});
  }

  const triggerFixWithAiRequest = () => {   
    EventManager.emitEvent('review-card-rows-panel:fix-with-ai-request', {});
  }

  
  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderCardRows = () => {
    const cardRowTrs = cardRows.map((card, index) => (
      <tr key={index} className={`${index % 2 === 0 ? 'even' : 'odd'} card`}>
        <td className='index'>{index + 1}</td>
        <td>{card.question}</td>
        <td>{card.answer}</td>
      </tr>
    ));

    return cardRowTrs;
  };

  const renderFixWithAiButton = () => {
    if (isAiListParser) {
      return null;
    }

    if (!isAiParsable) {
      return null;
    }

    return (
      <PillButton
        addClasses='resolve-modal-button fix-with-ai-button'
        isDisabled={isProcessing}
        isProcessing={false}
        icon={true}
        label='No, fix with AI'
        onClick={handleFixWithAiButtonClick}
      />
    )
  }

  const renderReviewInfo = () => {
    return (
      <>
          <p className='body-text'>This is a preview of the flashcards we're about to import into this deck.</p>
          <p className='body-text'>Note that the cards may include some formatting syntax that will be rendered nicely on the actual Study screen.</p>
          <a className="caption-link" href={FORMATTING_HELP_ARTICLE_URL} target='_blank' rel="nofollow noopener">Learn more about formatting.</a>
          <br/>
          <br/>
          <p className='body-text'>Once the cards have been imported into Brainscape, you'll be able to easily edit or delete individual flashcards as needed.</p>
          <a className="caption-link" href={IMPORT_HELP_ARTICLE_URL} target='_blank' rel="nofollow noopener">Learn more about importing.</a>
      </>
    );
  } 


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['review-card-rows-panel']);

  return (
    <div className={classes}>
      <div className='modal-title'>Do these look right to you?</div>
      
      <div className='card-list-container'>
        <table border='1' cellPadding='10' className='card-list'>
          <thead>
            <tr>
              <th className='index-header'>#</th>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            {renderCardRows()}
          </tbody>
        </table>
      </div>

      <div className='review-info'>
        <div className="field-caption">
          <div className="left-field-caption"></div> 
          <div className="right-field-caption">
            <div className="right-caption">
              <span className="caption-tip">What is this?</span>
              <DynamicTooltipIcon 
                addClasses="permissions-tooltip-button"
                delay={500}
                hasDismissButton={true}
                heading='Tips'
                body= {renderReviewInfo()}
                iconType='info'
                position='top'
                isMobileViewportSize={false}
              />
              <a className="caption-link" href={HELP_ARTICLE_URL} target='_blank' rel="nofollow noopener">Learn more.</a>
            </div>
          </div>
        </div>
      </div>

      <div className='modal-actions'>

        <SimpleTextButton
          addClasses='cancel-modal-text-button'
          label='Cancel'
          onClick={handleCancelButtonClick}
        />

        {renderFixWithAiButton()}

        <PillButton
          addClasses='resolve-modal-button pill-button-emphasized'
          isDisabled={false}
          isProcessing={isProcessing}
          label={`Yes, add ${cardRows.length} cards`}
          onClick={handleAddCardsButtonClick}
        />
      </div>
    </div>
  )
}

export default ReviewCardRowsPanel;
