
import EventManager                             from '@brainscape/event-manager';
import PropTypes                                from 'prop-types';
import React, { useState, useEffect, useRef }   from 'react';

import { toClassStr }                           from '_utils/UiHelper';


const PT = {
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  size: PropTypes.string,
  iconClass: PropTypes.string,
  svgIcon: PropTypes.node,
  onClick: PropTypes.func,
  tooltipContent: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipDelay: PropTypes.number,
};

const RoundedButton = ({ label, bgColor, size, iconClass, svgIcon, onClick, tooltipContent, tooltipPosition }) => {

  /*
  ==================================================
   INIT 
  ==================================================
  */

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const elemRef = useRef(null);

  useEffect(() => {
    return () => {
      triggerTooltipClose();
    };
  }, []);

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  const handleMouseEnter = () => {
    if (tooltipContent) {
      triggerTooltipOpen();
    }
  }

  const handleMouseLeave = () => {
    if (tooltipContent) {
      triggerTooltipClose();
    }
  }

  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: tooltipContent,
      elem: elemRef.current,
      position: tooltipPosition,
    });
  };

  const triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderIcon = () => {
    if (!(iconClass || svgIcon)) {
      return null;
    }

    if (svgIcon) {
      return svgIcon;
    }

    const classes = toClassStr(['icon', iconClass]);

    return (
      <i className={classes} />
    );
  }

  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['rounded-button', bgColor, size]);

  return (
    <div
      className={classes}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={elemRef}
    >
      {renderIcon()}
      {label}
    </div>
  );

}

RoundedButton.propTypes = PT;

export default RoundedButton;